import {Component} from '@angular/core';
import {CheckoutService} from '../../services/checkout.service';
import {HomeService} from '../../services/home.service';
import {Observable, of} from 'rxjs';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-order-confirmation',
    templateUrl: 'order-confirmation.component.html',
    styleUrls: ['order-confirmation.component.scss']
})

export class OrderConfirmationComponent {
    public orderNumber: any;
    public selectedOrganization: any;
    public displayPaymentMessage = false;
    public shippingOrder = false;
    public totalWaitTime: any;
    public shoppingCartItems$: Observable<any[]> = of ([]);
    public orderInfo: any;

    constructor(private _router: Router, private checkoutService: CheckoutService, private homeService: HomeService, private cartService: ShoppingCartService) {
        this.checkoutService.getOrderNumber().subscribe((oNumber) => {
         this.orderNumber = oNumber;
        });
        this.checkoutService.getOrderInfo().subscribe((orderInfo) => {
            this.orderInfo = orderInfo;
        })
        this.homeService.getOrganization().subscribe((selected) => {
            this.selectedOrganization = selected;
            if (this.selectedOrganization?.organization) {
                this.displayPaymentMessage = this.selectedOrganization.organization.enableOnlinePayment;
            }
            
        });
        this.checkoutService.getShippingInfo().subscribe((isShipping) => {
            this.shippingOrder = isShipping;
        });
    }

    continue() {
        this._router.navigateByUrl('home');
    }
}
