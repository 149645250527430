import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../components/module/Constants';
import { WebworkService } from './webwork.service';

@Injectable ()
export class MenuService {

    constructor(
        private http: HttpClient,
        private webworkService: WebworkService
    ) {
    }

    public getMenuDetailsById(id): Observable<any> {
        const apiPath = Constants.ERESTAU_DOMAIN + `/public/api/menuitems/id/${ id }`;
        // this.webworkService.logData ('get menu service: id', apiPath);
        return this.http.get (apiPath);
    }

    public getMenuDetailsByAlias(alias): Observable<any> {
        const apiPath = Constants.ERESTAU_DOMAIN + `/public/api/menuitems/${ alias }`;
        // this.webworkService.logData ('get menu service: alias', apiPath);
        return this.http.get (apiPath);
    }
}
