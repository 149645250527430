import { Injectable } from '@angular/core';

@Injectable ({
    providedIn: 'root'
})
export class GeolocationService {

    constructor() {
        // console.log ('geo location enabled');
    }
}
