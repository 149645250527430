<div class="app-footer">
    <div class="page-container">
        <div class="footer-upper">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <h3>Be Our Partner</h3>
                    <button class="btn btn-gray">Signup Business</button>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h3>Already Member?</h3>
                    <button class="btn btn-gray">Sign in</button>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h3>Join Us to Explore Offers!</h3>
                    <button class="btn btn-gray">Customer Signup</button>
                </div>
                <div class="col-lg-3 col-md-6">
                    <h3>Customer Support</h3>
                    <p><i class="fas fa-phone-square-alt"></i> +1 (678) 781 9356</p>
                    <p><i class="far fa-envelope"></i> info@erestau.com</p>
                </div>
            </div>
        </div>
        <div class="footer-lower">
            <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">Restaurants</a></li>
                <li><a href="#">Retails</a></li>
                <li><a href="#">Customer Support</a></li>
                <li><a href="#">Privacy</a></li>
                <li><a href="#">Terms and Conditions</a></li>
            </ul>
            <p>@copyright 2021</p>
        </div>
    </div>
</div>