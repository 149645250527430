<div class="sideNav-modal" (clickOutside)="onClickedOutside($event)">
    <div class="sideNav-header">
        <h4>Categories</h4> 
        <i (click)="closeSideNav()" class="far fa-times-circle"></i>
    </div>
    <div class="sideNav-search">
        <div class="search-box-gray">
            <input type="text" [(ngModel)]="filterValue" /> <i class="fas fa-search"></i>
        </div>
    </div>
    <div class="sidenav-list" *ngIf="sideNavData && sideNavData.length > 0">
        <ul>
            <li (click)="selectCat(item)" [ngClass]="{'active': item.isActive}" *ngFor="let item of sideNavData | arrayFilter: filterValue: 'name'">{{ item.name }}</li>
        </ul>
    </div>
</div>