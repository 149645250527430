import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-store-image',
  templateUrl: './store-image.component.html',
  styleUrls: ['./store-image.component.scss']
})
export class StoreImageComponent implements OnInit, OnChanges {

  @Input('imageUrl') imageUrl: any;
  @Input('isAvailable') isAvailable: any;
  @Input('ratting') ratting: any;
  @Input('foodType') foodType: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.imageUrl) {
      this.imageUrl = encodeURI(this.imageUrl);
    }
  }

  ngOnInit(): void {
  }

}
