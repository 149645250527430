import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { GeolocationService } from '../webwork-services/geolocation.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// var getDistanceBetweenPoints  = require('')

@Injectable ({
    providedIn: 'root'
})
export class WebworkService {

    public deliveryProximity: boolean;
    public location: GeoLocation;

    public geoLocatorUrl = 'http://maps.googleapis.com/maps/api/distancematrix/json?origins=33.9742436,-84.2072788&destinations=33.96859,-83.99573';

    constructor(
        private geo: GeolocationService,
        private cookies: CookieService,
        private http: HttpClient
    ) {
        this.getLocation ();
        this.deliveryProximity = false;
    }

    logData(location, dataToLog) {
        if ( !environment.production ) {
            // console.log (location + ': ');
            // console.log (dataToLog);
        }
    }

    getLocation() {
        // console.log ('checking location...');
        // if ( navigator.geolocation ) {
        //     navigator.geolocation.getCurrentPosition (this.checkPosition);
        // } else {
        //     this.logData ('Geo', 'location not supported');
        // }
    }

    checkPosition(position) {
        setTimeout (() => {
            // this.logData ('coordinates', position.coords);
            sessionStorage.setItem ('latitude', position.coords.latitude);
            sessionStorage.setItem ('longitude', position.coords.longitude);
        }, 1000);
    }


    public returnPosition() {
        setTimeout (() => {
            let myLat: number;
            let myLong: number;
            myLat = parseFloat (sessionStorage.getItem ('latitude'));
            myLong = parseFloat (sessionStorage.getItem ('longitude'));
            this.location = {
                latitude: myLat,
                longitude: myLong
            };
            this.logData ('webwork******', this.location);
            this.getDistance (myLat, myLong);
        }, 5000);
    }


    public getDistance(start, stop): Observable<any> {
        const oldheaders = {
            '': '*',
            'content-type': 'application/json'
        };
        const headers = new HttpHeaders ().set ('Access-Control-Allow-Origin', '*').set ('Access-Control-Allow-Headers', 'Origin, X-Requested-With').set ('Accept', 'application/json');
        const geoExampleStart = 'https://maps.googleapis.com/maps/api/distancematrix/json?mode=driving';
        const geoLoc = start + stop;
        const geoExampleEnd = '&key=' + environment.googleApiKey;
        const distanceApi = geoExampleStart + geoLoc + geoExampleEnd;
        this.logData ('distance matrix', distanceApi);
        // calculate if in range
        this.deliveryProximity = true;
        return this.http.get (distanceApi, { headers });
    }


}

export interface GeoLocation {
    latitude: number;
    longitude: number;
    accuracy?: number;
}
