import { Component, Input, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HomeService } from '../../../services/home.service';
import { Router } from '@angular/router';
import { Restaurant } from '../../../interface/restaurant';

@Component ({
    selector: 'app-restaurants-search',
    templateUrl: 'restaurants-search.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['restaurants-search.component.scss']
})

export class RestaurantsSearchComponent implements OnInit, OnChanges {
    @Input () public organizations: Array<any>;
    public isMobile: boolean;
    myControl = new FormControl ();
    // options: string[] = ['One', 'Two', 'Three'];
    filteredOptions: Array<any>;
    refreshMatAutoComplete: boolean = false;

    constructor(private responsiveService: ResponsiveService,
                private homeService: HomeService,
                private router: Router) {
    }

    ngOnInit() {
        this.onResize ();
        this.responsiveService.checkWidth ();
        this.myControl.valueChanges.subscribe((response) => {
            this.filteredOptions = this.filter(response);
        });
    }

    ngOnChanges() {
        this.filteredOptions = this.organizations;
        this.refreshMatAutoComplete = true;
    }

    private filter(value: string): string[] {
        this.refreshMatAutoComplete = false;
        const filterValue = value.toLowerCase();
        let output = this.organizations.filter(option => option.name.toLowerCase().includes(filterValue));
        this.refreshMatAutoComplete = true;
        return output;
    } 

    onResize() {
        this.responsiveService.getMobileStatus().subscribe(isMobile => {
            this.isMobile = isMobile;
        });
    }

    onSearchRestaurantClick(selected) {
        this.homeService.storeOrganization (selected);
        this.myControl.setValue("");
        this.router.navigateByUrl(`/menu/${ selected.alias }`);
        // this.router.navigate ([`/menu/${ selected.alias }`]);
    }
}
