import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './core/header/header.component';
import { MaterialModule } from './module/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RestaurantsSearchComponent } from './core/restaurants-search/restaurants-search.component';
import { CardComponent } from './core/cards/card.component';
import { ResponsiveService } from '../services/responsive.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './menu/menu.component';
import { MenuService } from '../services/menu.service';
import { SelectedProductDialogComponent } from './selected-product-dialog/selected-product-dialog.component';
import { MobileDetailPageComponent } from './core/mobile-detail-page/mobile-detail-page.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutService } from '../services/checkout.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MobileFooterComponent } from './core/mobile-footer/mobile-footer.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { HomeService } from '../services/home.service';
import { DebugService } from '../services/debug.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ImageComponent } from './image/image.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WebworkService } from '../services/webwork.service';
import { GeolocationService } from '../webwork-services/geolocation.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RetailHomeComponent } from './retail-home/retail-home.component';
import { MatRadioModule } from '@angular/material/radio';
import { FooterComponent } from './footer/footer.component';
import { MainTabComponent } from './main-tab/main-tab.component';
import { StoreImageComponent } from './store-image/store-image.component';
import { StoreStarComponent } from './store-star/store-star.component';
import { StoreDiscountComponent } from './store-discount/store-discount.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { ClickOutsideModule } from 'ng4-click-outside';

import { FilterPipe } from '../pipes/filter.pipe';
import { PaymentSummaryComponent } from './payment-summary/payment-summary.component';

@NgModule ({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        RestaurantsSearchComponent,
        CardComponent,
        MenuComponent,
        SelectedProductDialogComponent,
        MobileDetailPageComponent,
        ShoppingCartComponent,
        CheckoutComponent,
        MobileFooterComponent,
        OrderConfirmationComponent,
        ConfirmationDialogComponent,
        ImageComponent,
        RetailHomeComponent,
        FooterComponent,
        MainTabComponent,
        StoreImageComponent,
        StoreStarComponent,
        StoreDiscountComponent,
        SideNavComponent,
        FilterPipe,
        PaymentSummaryComponent
    ],
    entryComponents: [
        SelectedProductDialogComponent,
        ConfirmationDialogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MaterialModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CommonModule,
        StorageServiceModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatRadioModule,
        ClickOutsideModule
    ],
    providers: [
        ResponsiveService,
        MenuService,
        ShoppingCartService,
        CheckoutService,
        HomeService,
        DebugService,
        WebworkService,
        GeolocationService
        /* { provide: LocationStrategy, useClass: PathLocationStrategy }*/
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
