import { Component, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { ResponsiveService } from '../../services/responsive.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CheckoutService } from '../../services/checkout.service';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss']
})
export class PaymentSummaryComponent implements OnInit {

  public shoppingCartItems$: Observable<any[]> = of ([]);
  public totalAmount = 0;
  public organizationId: any;
  public taxAmount = 0;
  public itemTaxRate;
  public shoppingCartItems: any[] = [];
  public isMobile: boolean;
  public serviceFee: any;
  public deliveryFee: any;
  public shippingFee: any;
  public serviceRate: any;
  public selectedOption: string;
  public tenPercentTip: any;
  public fifteenPercentTip: any;
  public twentyPercentTip: any;
  public tipChoices: BehaviorSubject<any[]> = new BehaviorSubject ( []);
  public selectedTip: any;
  public amountWithTip: any;
  public delivery: any;
  public shipping: any;
  public isDeliverable: boolean;
  public isShippable: boolean;
  public shippingData: any;
  public cartTotal: number = 0;
  public addFormToggle: boolean = false;
  public orderMode: string = "delivery";
  public formValidity: boolean = false;
  public submitForm: boolean = false;

  public displayedColumns: string[] = ['Item', 'Description', 'Quantity', 'Price', 'Remove'];
  public dataSource = new MatTableDataSource<any> (this.shoppingCartItems);

  @ViewChild (MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
      private cartService: ShoppingCartService,
      private responsiveService: ResponsiveService,
      private checkoutService: CheckoutService,
      private router: Router, private zone: NgZone) {
      this.shoppingCartItems$ = this.cartService.getItems ();
      this.shoppingCartItems$.subscribe (items => {
          this.shoppingCartItems = items;
          this.organizationId = this.shoppingCartItems.length > 0 ? this.shoppingCartItems[0].organizationId : 0;
          if ( this.shoppingCartItems.length > 0 ) {
              this.itemTaxRate = this.shoppingCartItems[0].taxRate;
              this.calculateTotal();
          }
          
      });

  }

  ngOnInit() {
      this.onResize ();
      this.responsiveService.checkWidth ();
      this.getTotalAmount ();
      this.selectedOption = 'delivery';
      this.isDeliverable = true;
      this.updateTipAmount ();
  }

  goBack() {
    this.router.navigateByUrl("/shopping-cart");
  }

  toggleAddForm() {
    this.addFormToggle = !this.addFormToggle;
  }

  updatedOrderMode() {
    // console.log(this.orderMode);
  }


  calculateTotal() {
      let cartTotal = this.shoppingCartItems.map(item => item.finalPrice).reduce((total, price) => {
          return total + price;
      });
      this.cartTotal = cartTotal;
  }

  goToPaymentSummary() {
      this.router.navigateByUrl('/paymentSummary');
  }

  onResize() {
      this.responsiveService.getMobileStatus ().subscribe (isMobile => {
          this.isMobile = isMobile;
      });
  }

  public toggleDeliverable(method) {
      this.isDeliverable = method === 'delivery';
      this.isShippable = method === 'shipping';
      if (this.isShippable) {
          this.checkoutService.storeShippingBoolean(true);
      } else {
          this.checkoutService.storeShippingBoolean(false);
      }
      this.getTotalAmount ();
  }

  public removeItem(item: any) {
      this.cartService.removeFromCart (item);
      this.getTotalAmount ();
  }

  orderConformation(event) {
      this.cartService.removeAllFromCart ();
      this.zone.run (() => this.router.navigate (['/order-confirmation']));
  }

  extraFees(data) {
      this.serviceRate = data.serviceFeeAmount;
      this.deliveryFee = data.deliveryFeeAmount;
      this.getTotalAmount ();
  }

  getTotalAmount() {
      this.cartService.getShippingCharges(this.organizationId).subscribe((shippingData: any) => {
          this.shippingData = shippingData;
          this.totalAmount = 0;
          this.taxAmount = 0;
          if ( this.shoppingCartItems.length > 0 ) {
              const allPrices = this.shoppingCartItems.map ((item) => {
                  return item.finalPrice;
              });
              allPrices.map ((prices) => {
                  this.totalAmount += prices;
              });
              this.updateTipAmount ();
              const eachTaxAmount = this.shoppingCartItems.map ((item) => {
                  return ( item.finalPrice * item.onlineTaxRate ) / 100;
              });
              eachTaxAmount.map ((taxes) => {
                  this.taxAmount += taxes;
              });
              this.shippingData.forEach((shipping) => {
                  const minPrice = shipping.minOrderPrice;
                  const maxPrice = shipping.maxOrderPrice;
                  if (shipping.unitOfMeasurement === 'DOLLAR_AMOUNT') {
                      if ((this.totalAmount + this.taxAmount) > minPrice && (this.totalAmount + this.taxAmount) < maxPrice) {
                          this.shippingFee = shipping.fee;
                      }
                  } else {
                      if ((this.totalAmount + this.taxAmount) > minPrice && (this.totalAmount + this.taxAmount) < maxPrice) {
                          this.shippingFee = ((this.totalAmount + this.taxAmount) * shipping.fee) / 100;
                      }
                  }
              });

              if ( this.isDeliverable && !this.selectedTip && !this.isShippable ) {
                  this.totalAmount = this.totalAmount + this.taxAmount + this.deliveryFee;
              } else if ( this.isDeliverable && this.selectedTip && !this.isShippable ) {
                  this.totalAmount = this.totalAmount + this.taxAmount + this.deliveryFee + this.selectedTip;
              } else if ( !this.isDeliverable && this.selectedTip && !this.isShippable ) {
                  this.totalAmount = this.totalAmount + this.taxAmount + this.selectedTip;
              } else if ( !this.isDeliverable && !this.selectedTip && this.isShippable ) {
                  this.totalAmount = this.totalAmount + this.taxAmount + this.shippingFee;
              } else if ( !this.isDeliverable && this.selectedTip && this.isShippable ) {
                  this.totalAmount = this.totalAmount + this.taxAmount + this.shippingFee + this.selectedTip;
              } else {
                  this.totalAmount = this.totalAmount + this.taxAmount;
              }
          }
      });
  }

  updateTipAmount() {
      this.tenPercentTip = ( this.totalAmount * 10 ) / 100;
      this.fifteenPercentTip = ( this.totalAmount * 15 ) / 100;
      this.twentyPercentTip = ( this.totalAmount * 20 ) / 100;
      this.tipChoices.next( [
          {
              label: 'None',
              active: false,
              amount: 0,
          },
          {
              label: '10%',
              active: false,
              amount: this.tenPercentTip,
          },
          {
              label: '15%',
              active: false,
              amount: this.fifteenPercentTip,
          },
          {
              label: '20%',
              active: false,
              amount: this.twentyPercentTip,
          }
      ]);
  }

  getTheQtyEntered(type, selectedItem) {
      let convertToNumber = selectedItem.quantity;
      if (type == 'increment') {
          convertToNumber = ++selectedItem.quantity;
      } else {
          if (selectedItem.quantity > 1) {
              convertToNumber = --selectedItem.quantity;
          } else {
              this.removeItem(selectedItem);
          }
      }
      
      this.cartService.onQtyChange (convertToNumber, selectedItem);
      this.getTotalAmount ();
  }

  onSelectedOptionChange(selected) {
      this.getTotalAmount ();
  }

  onAddingTip(selectedAmount) {
      this.tipChoices.subscribe((tips) => {
          tips.map((tip) => {
              if (selectedAmount === tip.amount) {
                  tip.active = true;
              } else {
                  tip.active = false;
              }
          });
      });
      this.selectedTip = selectedAmount;
      this.getTotalAmount ();
  }

  formValidityCheck(event) {
    if (event != this.formValidity) {
        setTimeout(() => {
            this.formValidity = event;    
        }, 0);
    }
  }

  orderNow() {
      this.submitForm = true;
      setTimeout(() => {
          this.submitForm = false;
      }, 100);
  }

}
