import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-store-discount',
  templateUrl: './store-discount.component.html',
  styleUrls: ['./store-discount.component.scss']
})
export class StoreDiscountComponent implements OnInit {

  @Input('discount') discount: any;

  constructor() { }

  ngOnInit(): void {
  }

}
