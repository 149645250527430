import { Injectable } from '@angular/core';
import { HomeService } from './home.service';
import { WebworkService } from './webwork.service';
import { Organization } from '../interface/organization';

@Injectable ({
    providedIn: 'root'
})
export class LogoService {

    public companyLogo: string;
    public brand: string;
    public brandLogo: string;
    public selectedOrganization: any;
    public pageData: any;

    constructor(
        public homeService: HomeService,
        public webwork: WebworkService) {
        this.homeService.getOrganization ().subscribe ((selectedOrganization) => {
            this.selectedOrganization = selectedOrganization;
            this.setPageData (selectedOrganization);
        });
        this.brand = 'Erestau';
        this.companyLogo = '/assets/img/portalLogo.png';
        this.brandLogo = '/assets/img/portalLogo.png';
        // this.pageData.logoUrl = this.brandLogo;

    }

    public setPageData(pageData): void {
        this.pageData = pageData ? pageData.logoUrl : '';
        // this.webwork.getLocation();
        this.webwork.logData ('set page data: logo service', pageData);
    }

    public resetContext() {
        this.pageData = this.brandLogo;
    }
}

