import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { Router } from '@angular/router';
import { LogoService } from '../../services/logo.service';

@Component ({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
    @Input () public organization;
    @Input () public centerImage;
    @Input () public product;
    @Input () public cartItem;
    @Input () public menuItem;
    @Input () public imgPath: string;

    constructor(private homeService: HomeService,
                private router: Router,
                private logoService: LogoService
    ) {
    }

    ngOnInit() {
    }

    onGoToMenuClick(selected) {
        this.homeService.storeOrganization (selected);
        this.router.navigate ([`/menu/${ selected.alias }`]);
        this.logoService.setPageData (selected);
    }


}
