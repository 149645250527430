import {Component, Input, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {ResponsiveService} from '../../services/responsive.service';
import {ShoppingCartService} from '../../services/shopping-cart.service';

@Component({
  selector: 'app-selected-product-dialog',
  templateUrl: 'selected-product-dialog.component.html',
  styleUrls: ['selected-product-dialog.component.scss']
})

export class SelectedProductDialogComponent implements OnInit {
  @Input() public selectedProduct;
  public isMobile: Boolean;

  constructor(public dialogRef: MatDialogRef<SelectedProductDialogComponent>,
              private responsiveService: ResponsiveService,
              private cartService: ShoppingCartService) {}
  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onAddToCartFromDialog(selectedProduct) {
    this.cartService.storeOnLocalStorage(selectedProduct);
    this.dialogRef.close();
  }
}
