<!--&lt;!&ndash;TODO: validate form functionality&ndash;&gt;-->
<div class="main-search" *ngIf="refreshMatAutoComplete">
    <form class="search-container">
        <mat-form-field [floatLabel]="'never'" class="form-field-container">
            <i class="material-icons location-icon">location_on</i>
            <input [formControl]="myControl" [matAutocomplete]="auto" aria-label="Local Restaurants" class="input-field" matInput placeholder="Find Local Restaurants" type="text">
            <mat-autocomplete #auto="matAutocomplete" class="autocomplete-container">
                <mat-option (onSelectionChange)="onSearchRestaurantClick(option)" *ngFor="let option of filteredOptions" [value]="option.name" class="location-option">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
</div>

<!--<form (window:resize)="onResize($event)" *ngIf="isMobile" class="mobile-search-container">-->
<!--    <mat-form-field [floatLabel]="'never'">-->
<!--        <i class="material-icons location-icon">location_on</i>-->
<!--        <input [formControl]="myControl" [matAutocomplete]="auto" aria-label="Number" class="mobile-input-field" matInput placeholder="Find near Restaurants" type="text">-->
<!--        <mat-autocomplete #auto="matAutocomplete" class="mobile-autocomplete-container">-->
<!--            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" class="location-option">-->
<!--                {{option}}-->
<!--            </mat-option>-->
<!--        </mat-autocomplete>-->
<!--    </mat-form-field>-->
<!--</form>-->
