import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CardComponent} from '../cards/card.component';

@Component({
  selector: 'app-mobile-detail-page',
  templateUrl: 'mobile-detail-page.component.html',
  styleUrls: ['mobile-detail-page.component.scss']
})

export class MobileDetailPageComponent implements OnInit {
  @ViewChild(CardComponent, {static: true}) cardComponent;
  public selectedProduct: {};
  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    // console.log(this.cardComponent.selectedProduct);
  }
}
