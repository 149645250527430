import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private _toggleSideNav: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toggleSideNav = this._toggleSideNav.asObservable();

  private _sideNavData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public sideNavData = this._sideNavData.asObservable();

  private _selectedCategory: Subject<any> = new Subject();
  public selectedCategory = this._selectedCategory.asObservable();

  constructor() { }

  setToggleSideNav(flag) {
    this._toggleSideNav.next(flag);
  }

  setSideNavData(data) {
    this._sideNavData.next(data);
  }

  setSelectedCat(category) {
    this._selectedCategory.next(category);
  }
}
