import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnChanges {

  @Input('sideNavData') sideNavData: any;
  public filterValue: string = "";

  constructor(private _sideNav: SidenavService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    
  }

  closeSideNav() {
    this._sideNav.setToggleSideNav(false);
  }

  selectCat(item) {
    if (!item.isActive) {
      this.sideNavData.forEach((value) => {
        value.isActive = false;
      });
      item.isActive = true;
      this._sideNav.setSelectedCat(item);
      this.closeSideNav();
    }
  }

  onClickedOutside(event) {
    // console.log("clicked outside");
  }

}
