import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { Router } from '@angular/router';
import { LogoService } from '../../services/logo.service';
import { WebworkService } from '../../services/webwork.service';
import { Organization } from '../../interface/organization';
import { environment } from '../../../environments/environment';
import {ResponsiveService} from '../../services/responsive.service';
import { SharedService } from 'src/app/services/shared.service';

@Component ({
    selector: 'app-retail-home',
    templateUrl: 'retail-home.component.html',
    styleUrls: ['retail-home.component.scss']
})

export class RetailHomeComponent implements OnInit {

    public organizations: Organization[];
    public distanceForDelivery: any;
    public customerLocation: any;
    public deliverableToCustomer: boolean;
    public distanceInfo: any;
    public debug: boolean;
    public restaurants = [];
    public groceryStores =  [];
    public restaurantPanelOpen = true;
    public groceryPanelOpen = false;
    public isMobile: Boolean;
    step = 0;
    public sortType: string = null;

    constructor(
        private homeService: HomeService,
        private router: Router,
        private logoService: LogoService,
        public webwork: WebworkService,
        private responsiveService: ResponsiveService,
        private _shared: SharedService
    ) {
        this.distanceInfo = 'test data';
    }

    ngOnInit() {
        // console.log("loaded"); 
        this.onResize();
        setTimeout(() => {
            this._shared.setLoader(true); 
        }, 0);
        this.distanceForDelivery = 9;
        this.homeService.getOrganizations ().subscribe ((organizations) => {
            this._shared.setLoader(false);
            this.organizations = organizations;
            organizations.forEach((organization) => {
                if (organization.restaurant) {
                    this.restaurants.push(organization);
                } else {
                    this.groceryStores.push(organization);
                }
            })
            this.webwork.logData ('home component', organizations);
            this.logoService.resetContext ();
        });
        this.deliverableToCustomer = false;
        this.logoService.resetContext ();
        this.homeService.removeSelectedOrganization ();
        this.customerLocation = this.webwork.returnPosition ();
        this.webwork.getDistance (1, 2);
        this.debug = !environment.production;
        // CONTROL
        this.debug = false;

    }


    sortProduct(type) {
        this.sortType = type;
        if (this.sortType == 'delivery') {
            this.groceryStores.sort((a, b) => {
                return b.deliveryAvailable - a.deliveryAvailable;
            });
        } else {
            this.groceryStores.sort((a, b) => {
                return a.deliveryAvailable - b.deliveryAvailable;
            });
        }
        
    }

    onGoToMenuClick(selected) {
        this.homeService.storeOrganization (selected);
        this.logoService.setPageData (selected);
        this.router.navigate ([`/menu/${ selected.alias }`]);
    }

    checkDeliveryStatus(resLat, resLong, custLat, custLong) {
        const origins = '&origins=' + resLat + ',' + resLong;
        const destinations = '&destinations=' + custLat + ',' + custLong;
        this.webwork.getDistance (origins, destinations).subscribe (data => {
            this.distanceInfo = data;
            // console.log (this.distanceInfo);
            this.deliverableToCustomer = this.distanceInfo.rows.elements.distance.value < 26000;
        });
    }

    getKlm(miles) {
        // const klm = miles / 0.621371;
        const klm = miles;
        return klm;
    }

    onResize() {
        this.responsiveService.getMobileStatus().subscribe(isMobile => {
            this.isMobile = isMobile;
        });
    }

    getMiles(klm) {
        const miles = Math.floor (klm * 0.621371);
        return miles;
    }

}
