<!--The content below is only a placeholder and can be replaced.-->
<div class="main-wrapper" (window:resize)="onResize()">
  <div class="header">
    <app-header></app-header>
  </div>
  <div class="side-nav animate__slideInLeft animate__animated" *ngIf="toggleSideNav">
    <app-side-nav [sideNavData]="sideNavData"></app-side-nav>
  </div>
  <div class="main-tab" *ngIf="pageTitle == 'homepage'">
      <app-main-tab></app-main-tab>
  </div>
  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <app-footer></app-footer>
    <!-- <app-mobile-footer *ngIf="isMobile"></app-mobile-footer> -->
  </div>
</div>
<div class="page-loader" *ngIf="globalLoader">
  <mat-spinner></mat-spinner>
</div>
