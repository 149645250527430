import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ResponsiveService} from '../../../services/responsive.service';
import {Router} from '@angular/router';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {Observable, of} from 'rxjs';
import {HomeService} from '../../../services/home.service';
import {ConfirmationDialogComponent} from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-mobile-footer',
    templateUrl: 'mobile-footer.component.html',
    styleUrls: ['mobile-footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MobileFooterComponent implements OnInit {

    public itemsInCart$: Observable<any[]> = of([]);
    public footerOrganizationAlias: any;
    public totalItemsInCart = 0;
    public footerTab: any[] = [
        {
            label: 'Home',
            active: false,
            displayOnBottom: true,
            disable: false
        },
        {
            label: 'Menu',
            active: false,
            displayOnBottom: true,
            disable: false
        },
        {
            label: 'Checkout',
            active: false,
            displayOnBottom: true,
            disable: false
        }
    ];

    constructor(private responsiveService: ResponsiveService,
                private router: Router,
                private cartService: ShoppingCartService,
                private homeService: HomeService,
                public dialog: MatDialog) {
        this.itemsInCart$ = this
            .cartService
            .getItems();

        this.itemsInCart$.subscribe((items) => {
            const allQuantity = items.map((selected) => {
                return selected.quantity;
            });
            this.totalItemsInCart = allQuantity.reduce((a, b) => {
                return a + b;
            }, 0);
            if (this.totalItemsInCart > 0) {
                this.footerTab[0].disable = false;
                this.footerTab[1].disable = false;
            } else {
                this.footerTab[0].disable = false;
                this.footerTab[1].disable = true;
            }
        });
        this.homeService.getOrganization().subscribe((selectedOrganization) => {
            if (selectedOrganization) {
                this.footerOrganizationAlias = selectedOrganization;
            }
        });
    }

    ngOnInit() {
        if (window.location.href.includes('menu')) {
            this.footerTab[1].active = true;
        } else if (window.location.href.includes('home')) {
            this.footerTab[0].active = true;
        } else {
            this.footerTab[1].active = false;
            this.footerTab[0].active = false;
        }
    }

    changeTab(tab) {
      if (tab === 0) {
          if (this.totalItemsInCart > 0) {
              const dialogRef = this.dialog.open(ConfirmationDialogComponent);
              dialogRef.afterClosed().subscribe(item => {
              });
          } else {
              this.router.navigateByUrl('/home');
              this.footerTab[0].active = true;
              this.footerTab[1].active = false;
              this.footerTab[2].active = false;
          }
      } else if (tab === 1) {
        this.router.navigateByUrl(`/menu/${this.footerOrganizationAlias.organization.alias}`);
        this.footerTab[1].active = true;
        this.footerTab[0].active = false;
        this.footerTab[2].active = false;
      } else if (tab === 2) {
        this.router.navigateByUrl('/shopping-cart');
        this.footerTab[2].active = true;
        this.footerTab[1].active = false;
        this.footerTab[0].active = false;
      }
    }
}

