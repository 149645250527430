<div class="page-container">
    <div class="payment-summary-page" *ngIf="shoppingCartItems.length > 0">
        <div class="row">
            <div class="col-md-6 col-lg-5">
                <div class="radius-header">
                    <h2>Payment Details</h2>
                </div>
                <div class="radius-container">
                    <!-- <div class="radius-sub-section">
                        <h2>Apply Discount</h2>
                        <div class="discount-section">
                            <input type="text" placeholder="Coupon Code" />
                            <button class="btn btn-green">Apply</button>
                        </div>
                    </div> -->
                    <div class="radius-sub-section">
                        <h2>Tips</h2>
                        <div class="tip-section">
                            <ul>
                                <li *ngFor="let tip of tipChoices | async" (click)="onAddingTip(tip.amount)" [ngClass]="{'active': tip.active}">
                                    <label>{{tip.label}}</label>
                                    <span>${{tip.amount.toFixed(2)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="cart-total-section">
                        <div class="cart-total-row">
                            <label>Sub Total:</label>
                            <span>${{cartTotal.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div class="cart-total-section">
                        <div class="cart-total-row">
                            <label *ngIf="isDeliverable">Delivery Fee (+)</label>
                            <label *ngIf="isShippable">Shipping Fee (+)</label>
                            <span *ngIf="isDeliverable">${{deliveryFee ? deliveryFee.toFixed(2) : 0}}</span>
                            <span *ngIf="isShippable">${{shippingFee ? shippingFee.toFixed(2) : 0}}</span>
                        </div>
                        <!-- <div class="cart-total-row">
                            <label>Discount (-):</label>
                            <span class="color-green">$0</span>
                        </div> -->
                        <div class="cart-total-row">
                            <label>Tip (+):</label>
                            <span class="color-red">${{selectedTip ? selectedTip.toFixed(2) : 0}}</span>
                        </div>
                        <div class="cart-total-row">
                            <label>Tax and Charges (+):</label>
                            <span class="color-orange">${{taxAmount.toFixed(2)}}</span>
                        </div>
                    </div>
                    <div class="cart-total-section">
                        <div class="cart-total-row grand-total">
                            <label>Grand Total:</label>
                            <span>${{totalAmount.toFixed(2)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-7 mb-pad-t-20">
                <div class="radius-header">
                    <h2></h2>
                    <div>
                        <span class="custom-radio">
                            <input 
                                id= "delivery_option"
                                type="radio" 
                                name="deliveryOption" 
                                value="delivery"
                                [(ngModel)]="orderMode"
                                (change)="toggleDeliverable('delivery')"
                            />
                            <label for="delivery_option"><i class="fas fa-check"></i></label>
                        </span>
                        <label for="delivery_option">Delivery</label>
                    </div>
                    <div>
                        <span class="custom-radio">
                            <input 
                                id= "pickup_option"
                                type="radio" 
                                name="deliveryOption" 
                                value="pickup"
                                [(ngModel)]="orderMode"
                                (change)="toggleDeliverable('pickup')"
                            />
                            <label for="pickup_option"><i class="fas fa-check"></i></label>
                        </span>
                        <label for="pickup_option">Pickup</label>
                    </div>
                    <div *ngIf="shippingData?.length > 0">
                        <span class="custom-radio">
                            <input 
                                id= "shipping_option"
                                type="radio" 
                                name="deliveryOption" 
                                value="shipping"
                                [(ngModel)]="orderMode"
                                (change)="toggleDeliverable('shipping')"
                            />
                            <label for="shipping_option"><i class="fas fa-check"></i></label>
                        </span>
                        <label for="shipping_option">Shipping</label>
                    </div>
                </div>
                <div class="radius-container">
                    <div class="radius-sub-section">
                        <h2>Choose Address</h2>
                        <!-- <div class="existing-address">
                            <div class="row">
                                <div class="col-md-12 col-lg-6">
                                    <div class="existing-address-item">
                                        <span class="custom-radio">
                                            <input 
                                                id= "address_1"
                                                type="radio" 
                                                name="existingAddress" 
                                            />
                                            <label for="address_1"><i class="fas fa-check"></i></label>
                                        </span>
                                        <p>
                                            A-203, Main Tower, <br>
                                            Near Main Street, Beside A <br>
                                            New York, 3456657
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-6">
                                    <div class="existing-address-item">
                                        <span class="custom-radio">
                                            <input 
                                                id= "address_2"
                                                type="radio" 
                                                name="existingAddress" 
                                            />
                                            <label for="address_2"><i class="fas fa-check"></i></label>
                                        </span>
                                        <p>
                                            A-203, Main Tower, <br>
                                            Near Main Street, Beside A <br>
                                            New York, 3456657
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    <!-- </div>
                    <div class="radius-sub-section"> -->
                        <!-- <button *ngIf="!addFormToggle" (click)="toggleAddForm()" class="btn btn-green-border">ADD NEW ADDRESS</button>
                        <ng-container *ngIf="addFormToggle">
                            <h2>Add New Address</h2>
                            <div class="add-address-form">
                                <form>
                                    <div class="form-row">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-6">
                                                <input type="text" placeholder="Firstname" class="form-control" />
                                            </div>
                                            <div class="col-md-12 col-lg-6">
                                                <input type="text" placeholder="Lastname" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-6">
                                                <input type="text" placeholder="Street" class="form-control" />
                                            </div>
                                            <div class="col-md-12 col-lg-6">
                                                <input type="text" placeholder="City" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-6">
                                                <input type="text" placeholder="State" class="form-control" />
                                            </div>
                                            <div class="col-md-12 col-lg-6">
                                                <input type="text" placeholder="Zipcode" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-6">
                                                <input type="text" placeholder="Phone No." class="form-control" />
                                            </div>
                                            <div class="col-md-12 col-lg-6">
                                                <input type="text" placeholder="Email (Optional)" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-btn-row">
                                        <button class="btn btn-green-border">ADD NEW</button>
                                    </div>
                                </form>
                            </div>
                        </ng-container> -->
                        <app-checkout
                                (extraFees)="extraFees($event)"
                                (orderConformation)="orderConformation($event)"
                                (formValidity)="formValidityCheck($event)"
                                [delivery]="isDeliverable"
                                [shipping]="isShippable"
                                [shippingAmount]="shippingFee"
                                [organizationId]="organizationId"
                                [selectedTip]="selectedTip"
                                [shoppingCartItems]="shoppingCartItems"
                                [totalAmount]="totalAmount"
                                [submitForm]="submitForm"
                                >
                        </app-checkout>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-button-row">
            <div class="left-button" (click)="goBack()">
                <button class="btn btn-green-border">BACK</button>
            </div>
            <div class="right-button">
                <button class="btn btn-green" (click)="orderNow()" [disabled]="!formValidity">ORDER NOW</button>
            </div>
        </div>
    </div>
</div>