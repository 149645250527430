<!-- <mat-card (window:resize)="onResize()" [ngClass]="{'example-card': !isMobile, 'mobile-example-card': isMobile}">
    <mat-card-content (click)="onCardClick(products)">
        <app-image [product]="products"></app-image>
        <ul class="product">
            <li class="product-name">{{productName}}</li>
            <li class="product-price">${{products.price}}</li>
            <li class="product-description">{{products.description}}</li>
        </ul>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="center">
        <button (click)="onAddToCartClick(products)" *ngIf="!productAdded && products.activeOnline" color="primary" mat-raised-button>ADD TO CART</button>
        <button (click)="updateButtonForProduct(products)" *ngIf="productAdded && products.activeOnline" color="accent" mat-raised-button>Add Another</button>
        <button *ngIf="!products.activeOnline" color="primary" mat-raised-button>Unavailable</button>
    </mat-card-actions>
</mat-card> -->


<div class="store-item">
    <div class="store-item-hover"></div>
    <div class="store-item-image">
        <app-store-image 
        [imageUrl]="products.imageUrl" 
        [ratting]="'4.8'"
        ></app-store-image>
    </div>
    <div class="store-item-body">
        <div class="store-container">
            <h2>{{products.name}}</h2>
            <p>{{products.description}}</p>
        </div>
        <div class="price-row">
            <label>{{products.price | currency}}</label>
            <button class="btn btn-green" (click)="onAddToCartClick(products)" *ngIf="!currentQuanity && products.activeOnline">
                <i class="fas fa-plus"></i> ADD
            </button>
            <button class="btn btn-green-border" style="cursor: auto;" *ngIf="currentQuanity && products.activeOnline">
                <i (click)="removeProduct(products)" class="fas fa-minus" style="cursor: pointer;"></i>
                <span style="padding: 0 10px;">{{ currentQuanity }}</span>
                <i (click)="updateButtonForProduct(products)" class="fas fa-plus" style="cursor: pointer;"></i>
            </button>
            <button *ngIf="!products.activeOnline" class="btn btn-green-border">Unavailable</button>
        </div>
    </div>
</div>
