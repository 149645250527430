<!-- <h3 class="order-message">CONGRATULATIONS</h3>

    <div style="width: 100%; text-align: center; font-size: 18px;"> 
    
        <div *ngIf="displayPaymentMessage && !shippingOrder">Your order number is {{orderNumber}}, and Your order will be ready in 30 minutes. <br/><br/></div>
        <div *ngIf="!displayPaymentMessage && shippingOrder">Your order number is {{orderNumber}}, and Your order will Shipped today. <br/><br/></div>
        <div *ngIf="!displayPaymentMessage && !shippingOrder">Your order number is {{orderNumber}}, and Your order will be ready in 30 minutes. Pay for your order upon pickup or delivery.<br/><br/></div>

        <div style="width: 100%; text-align: center; font-size: 12px;"> 
            <strong>{{selectedOrganization.organization.name}}</strong><br/>
            {{selectedOrganization.street}}<br/>
            {{selectedOrganization.city}}, {{selectedOrganization.state}} {{selectedOrganization.zipCode}}<br/>
            Phone: {{selectedOrganization.organization.phoneNumber}}
        </div>
    
    </div> -->

    <div class="page-container">
        <div class="payment-summary-page" *ngIf="orderInfo">
            <div class="row">
                <!-- <div class="col-md-12 col-lg-5">
                    <div class="radius-header">
                        <h2>Order Summary</h2>
                    </div>
                    <div class="radius-container">
                        <div class="ordered-item-list">
                            <h4><span>Order Id :</span> OD123443453</h4>
                            <div class="order-list">
                                <div class="order-list-item">
                                    <div class="order-col-label">
                                        <h6>
                                            <span class="food-type">
                                                <span class="food-symbol green">
                                                    <span class="food-symbol-inner"></span>
                                                </span>
                                            </span>
                                            Chicken Suya
                                        </h6>
                                        <p>Marinated Beef and Grilled with Butter</p>
                                    </div>
                                    <div class="order-col order-count">
                                        <label>1</label>
                                    </div>
                                    <div class="order-col">
                                        <label>$200</label>
                                    </div>
                                </div>
                                <div class="order-list-item">
                                    <div class="order-col-label">
                                        <h6>
                                            <span class="food-type">
                                                <span class="food-symbol green">
                                                    <span class="food-symbol-inner"></span>
                                                </span>
                                            </span>
                                            Chicken Suya
                                        </h6>
                                        <p>Marinated Beef and Grilled with Butter</p>
                                    </div>
                                    <div class="order-col order-count">
                                        <label>1</label>
                                    </div>
                                    <div class="order-col">
                                        <label>$200</label>
                                    </div>
                                </div>
                                <div class="order-list-item">
                                    <div class="order-col-label">
                                        <h6>
                                            <span class="food-type">
                                                <span class="food-symbol green">
                                                    <span class="food-symbol-inner"></span>
                                                </span>
                                            </span>
                                            Chicken Suya
                                        </h6>
                                        <p>Marinated Beef and Grilled with Butter</p>
                                    </div>
                                    <div class="order-col order-count">
                                        <label>1</label>
                                    </div>
                                    <div class="order-col">
                                        <label>$200</label>
                                    </div>
                                </div>
                                <div class="order-list-item">
                                    <div class="order-col-label">
                                        <h6>
                                            <span class="food-type">
                                                <span class="food-symbol green">
                                                    <span class="food-symbol-inner"></span>
                                                </span>
                                            </span>
                                            Chicken Suya
                                        </h6>
                                        <p>Marinated Beef and Grilled with Butter</p>
                                    </div>
                                    <div class="order-col order-count">
                                        <label>1</label>
                                    </div>
                                    <div class="order-col">
                                        <label>$200</label>
                                    </div>
                                </div>
                                <div class="order-list-item">
                                    <div class="order-col-label">
                                        <h6>
                                            <span class="food-type">
                                                <span class="food-symbol green">
                                                    <span class="food-symbol-inner"></span>
                                                </span>
                                            </span>
                                            Chicken Suya
                                        </h6>
                                        <p>Marinated Beef and Grilled with Butter</p>
                                    </div>
                                    <div class="order-col order-count">
                                        <label>1</label>
                                    </div>
                                    <div class="order-col">
                                        <label>$200</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cart-total-section">
                            <div class="cart-total-row">
                                <label>Sub Total:</label>
                                <span>$100</span>
                            </div>
                        </div>
                        <div class="cart-total-section"> -->
                            <!-- <div class="cart-total-row">
                                <label *ngIf="isDeliverable">Delivery Fee (+)</label>
                                <label *ngIf="isShippable">Shipping Fee (+)</label>
                                <span *ngIf="isDeliverable"></span>
                                <span *ngIf="isShippable"></span>
                            </div> -->
                            <!-- <div class="cart-total-row">
                                <label>Tip (+):</label>
                                <span class="color-red">$10</span>
                            </div>
                            <div class="cart-total-row">
                                <label>Tax and Charges (+):</label>
                                <span class="color-orange">$20</span>
                            </div>
                        </div>
                        <div class="cart-total-section">
                            <div class="cart-total-row grand-total">
                                <label>Grand Total:</label>
                                <span>$130</span>
                            </div>
                        </div>
                        <div class="cart-paid-by">
                            <h6><span>PAID</span> by Credit Card</h6>
                            <p>12/05/2020 12:00 AM</p>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-12 col-lg-12 md-pad-t-20">
                    <!-- <div class="radius-header">
                        <h2>Order Tracking</h2>
                    </div> -->
                    <div class="radius-container fit-content">
                        <div class="order-confirmation">
                            <div class="order-confirmation-message">
                                <span><i class="far fa-file-alt"></i></span> <br>
                                <h6 style="padding-bottom: 7px;">{{orderInfo.name}},</h6>
                                <p>Thank you for ordering with us. <br>
                                    For Tracking purpose, Your order number is <b>{{orderInfo.orderNumber}}</b>.</p>
                                    <button class="btn btn-green" (click)="continue()">Continue</button>
                            </div>
                            <!-- <div class="order-confirmation-message">
                                <span><i class="far fa-file-alt"></i></span> <br>
                                <h6>Your Order Has been Confirmed by Restaurent.</h6>
                            </div> -->
                            <!-- <div class="order-progress-bar">
                                <ul>
                                    <li class="active">
                                        <span class="progress-icon">
                                            <i class="fas fa-check"></i>
                                        </span>
                                        <h6>Order Sent</h6>
                                        <p>10/5/2021 9:00 AM</p>
                                    </li>
                                    <li class="active">
                                        <span class="progress-icon">
                                            <i class="fas fa-check"></i>
                                        </span>
                                        <h6>Confirmed</h6>
                                        <p>10/5/2021 9:00 AM</p>
                                    </li>
                                    <li>
                                        <span class="progress-icon">
                                            <i class="fas fa-check"></i>
                                        </span>
                                        <h6>On The Way</h6>
                                        <p>10/5/2021 9:00 AM</p>
                                    </li>
                                    <li>
                                        <span class="progress-icon">
                                            <i class="fas fa-check"></i>
                                        </span>
                                        <h6>Delivered</h6>
                                        <p>10/5/2021 9:00 AM</p>
                                    </li>
                                </ul>
                            </div> -->
                            <!-- <div class="order-id-row">
                                <h4><span>Order Id :</span> OD123443453</h4>
                                <button class="btn btn-green">CANCEL ORDER</button>
                            </div> -->
                            <!-- <div class="order-address-details">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5>Restaurent Address</h5>
                                        <label>{{ selectedOrganization.name }}</label>
                                        <p>
                                            {{ selectedOrganization.address.street }} <br>
                                            {{ selectedOrganization.address.city }}, {{ selectedOrganization.address.state }} <br>
                                            {{ selectedOrganization.address.country }}, {{ selectedOrganization.address.zipCode }}
                                        </p>
                                    </div>
                                    <div class="col-md-6">
                                        <h5>Customer Address</h5>
                                        <label>John Deo</label>
                                        <p>
                                            A-203, Main Tower, <br>
                                            Near Main Street, Beside A <br>
                                            New York, 3456657
                                        </p>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="call-support">
                            <div class="call-support-container">
                                <h5>If something gone wrong with your order. We are here to help you.</h5>
                                <button class="btn btn-green">CALL US</button>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>