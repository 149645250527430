<div class="store-image-container" [ngClass]="{'no-image': !imageUrl}">
    <span class="store-status" *ngIf="isAvailable === false">
        Order For Pickup
    </span>
    <span class="store-status blue-status" *ngIf="isAvailable === true">
        Order For Delivery
    </span>
    <span class="store-ratting" *ngIf="ratting"><app-store-star [star]="ratting"></app-store-star></span>
    <span class="food-type" *ngIf="foodType">
        <span class="food-symbol" [ngClass]="{'green': foodType == 'veg', 'red': foodType == 'nonVeg'}">
            <span class="food-symbol-inner"></span>
        </span>
    </span>
    <div *ngIf="imageUrl" class="image-as-bg" [style.backgroundImage]="'url(&quot;' + imageUrl + '&quot;)'"></div>
    <!-- <ng-container *ngIf="imageUrl">
        <img [src]="imageUrl" />
    </ng-container> -->
    
</div>