<div class="main-tab-container">
    <ul>
        <li [routerLink]="['grocery']" routerLinkActive="active">
            <span class="tab-icon">
                <i class="fas fa-shopping-cart"></i>
            </span>
            <span class="tab-content">
              <h4>Grocery</h4>
              <p>Online Orders Grocery</p>
            </span>
        </li>
        <li [routerLink]="['home']" routerLinkActive="active">
            <span class="tab-icon">
                <i class="fas fa-hamburger"></i>
            </span>
            <span class="tab-content">
              <h4>Restaurant</h4>
              <p>Online Orders Delicious Food</p>
            </span>
        </li>
    </ul>
</div>