import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from '../components/module/Constants';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { WebworkService } from './webwork.service';

const STORAGE_KEY = 'stored_organization';

@Injectable ()
export class HomeService {
    private organization: BehaviorSubject<{}> = new BehaviorSubject (this.storage.get (STORAGE_KEY) || null);

    constructor(
        private http: HttpClient,
        private webwork: WebworkService,
        @Inject (LOCAL_STORAGE) private storage: StorageService) {
    }

    public getOrganizations(): Observable<any> {
        const apiPath = Constants.ERESTAU_DOMAIN + `/public/api/organizationswithproperties`;
        this.webwork.logData ('home service', apiPath);
        return this.http.get (apiPath);
    }

    public getDeliveryOption(): Observable<any> {
        const apiPath = this.webwork.geoLocatorUrl;
        return this.http.get (apiPath);
    }

    // public getAddresses(): Observable<any> {
    //     const apiPath = Constants.ERESTAU_DOMAIN + `/public/api/addresses`;
    //     return this.http.get (apiPath);
    // }
    //
    // public getDeliveryTime(alias): Observable<any> {
    //     const apiPath = Constants.ERESTAU_DOMAIN + `/public/api/deliveryinfo/${ alias }`;
    //     return this.http.get (apiPath);
    // }

    public storeOrganization(selectedOrganization): void {
        this.storage.set (STORAGE_KEY, selectedOrganization);
        this.organization.next(selectedOrganization);
        this.webwork.logData ('home service/store organization', selectedOrganization);
        this.webwork.getLocation();
    }

    public removeSelectedOrganization() {
        this.storage.remove (STORAGE_KEY);
        this.organization.next ({});
    }

    public getOrganization() {
        return this.organization;
    }
}
