<div (window:resize)="onResize()" *ngIf="!isMobile" class="dialog-container">
    <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="item-name">
            {{selectedProduct.name}}
        </div>
        <div class="item-name">
            ${{selectedProduct.price}}
        </div>
    </div>
    <div class="dialog-header">
        <app-image [menuItem]="selectedProduct" class="shopping-cart-image"></app-image>
    </div>
    <div class="detail-container">
        <!--    TODO: theme styling for all descriptions-->
        {{selectedProduct.description}}
    </div>
    <div class="button-container" fxLayout="row" fxLayoutAlign="space-around center">
        <button (click)="onAddToCartFromDialog(selectedProduct)" color="primary" mat-raised-button>ADD TO CART</button>
        <button (click)="onCancelClick()"  color="warn" mat-raised-button>CANCEL</button>
    </div>
</div>

<div (window:resize)="onResize()" *ngIf="isMobile" class="mobile-dialog-container">
    <div class="mobile-dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <i (click)="onCancelClick()" class="material-icons back-icon">keyboard_backspace</i>
        </div>
        <div class="item-name">
            {{selectedProduct.name}}
        </div>
        <div class="item-name">
            ${{selectedProduct.price}}
        </div>
    </div>
    <div class="dialog-header">
        <img [src]="selectedProduct.imageUrl ? selectedProduct.imageUrl : 'http://mungaka.com/erestauImg/jayscaribbean/zz_oxtail.png'" alt="Sorry, Image not found" class="mobile-dialog-image"
             mat-card-image>
    </div>
    <div class="mobile-detail-container">
        {{selectedProduct.description}}
    </div>
    <div class="mobile-button-container" fxLayout="row" fxLayoutAlign="space-around center">
        <button (click)="onAddToCartFromDialog(selectedProduct)" class="dialog-button" color="primary" mat-raised-button>ADD TO CART</button>
        <button (click)="onCancelClick()" class="dialog-button" color="warn" mat-raised-button>CANCEL</button>
    </div>
</div>

