import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // private currentFlag: boolean;

  private _globalLoader: Subject<boolean> = new Subject<boolean>();
  public globalLoader = this._globalLoader.asObservable();

  setLoader(flag) {
    // if (flag != this.currentFlag) {
    //   this.currentFlag = flag;
    //   // this._globalLoader.next(flag);
    // }

    this._globalLoader.next(flag);
    
  }

  constructor() { }
}
