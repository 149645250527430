import {Component, Input, OnInit, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators, NgForm} from '@angular/forms';
import {CheckoutService} from '../../services/checkout.service';
import {HomeService} from '../../services/home.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-checkout',
    templateUrl: 'checkout.component.html',
    styleUrls: ['checkout.component.scss']
})

export class CheckoutComponent implements OnInit, OnChanges {
    @Input() shoppingCartItems: any;
    @Input() totalAmount: any;
    @Input() selectedTip: any;
    @Input() organizationId: any;
    @Input() delivery: any;
    @Input() shipping: any;
    @Input() shippingAmount: any;
    @Input() submitForm: boolean;
    @Output() public orderConformation = new EventEmitter();
    @Output() public extraFees = new EventEmitter();
    @Output() public formValidity = new EventEmitter();

    @ViewChild('addressForm') addressForm;

    public dataTokenizationKey: any;
    public todayDate: any;
    public fName;
    public fullName;
    public phoneNumber;
    public email;
    public lName;
    public address;
    public city;
    public state;
    public zip;
    public organizationAlias: any;
    public displayPaymentCheckOut = false;
    public checkoutLoading = false;

    constructor(private checkoutService: CheckoutService, private _shared: SharedService, private formBuilder: FormBuilder, private homeService: HomeService) {
    }

    ngOnChanges() {
      if (this.submitForm) {
        this.loadScript();
      }
    }

    ngOnInit() {
        this.todayDate = new Date();
        this.isFormValid();
        this.checkoutService.getDataTokenizationKey(this.organizationId).subscribe((data: any) => {
            if (data) {
                this.extraFees.emit(data);
                this.dataTokenizationKey = data.dataTokenizationKey.toString();
                const node = document.createElement('script');
                node.src = 'https://secure.networkmerchants.com/token/Collect.js';
                node.type = 'text/javascript';
                node.async = false;
                node.setAttribute('data-tokenization-key', this.dataTokenizationKey);
                document.getElementsByTagName('head')[0].appendChild(node);
            }
        });
        this.homeService.getOrganization ().subscribe ((selectedOrganization) => {
            if ( selectedOrganization ) {
                this.organizationAlias = selectedOrganization;
                this.displayPaymentCheckOut = this.organizationAlias.enableOnlinePayment;
            }
        });
    }

    public loadScript() {
        ( window as any).CollectJS.startPaymentRequest();
        ( window as any).CollectJS.configure({
            variant: 'lightbox',
            callback: (token) => {
              if (token) {
                  this._shared.setLoader(true);
                  this.checkoutLoading = true;
                  this.checkoutService.sendToken(token, this.totalAmount, this.organizationId).subscribe((response) => {
                      if (response.response === 1 || response.responseText === 'APPROVED' || response.responseText === 'SUCCESS') {
                        this.checkoutService.storeOrderNumber(response.orderNumber);
                        this.sendDataToPos(response);
                      }
                  });
              }
            }
        });
    }

    onSubmitWithoutPayment() {
      this.sendDataToPos(null);
    }

    isFormValid() {
      let valid = true;
      if ((!this.fName || !this.lName || !this.address || !this.state || !this.city || !this.zip || !this.phoneNumber) && this.delivery) {
        valid = false;
      }
      if ((!this.fName || !this.lName || !this.phoneNumber) && !this.delivery) {
        valid = false;
      }
      this.formValidity.emit(valid);
      return valid;
    }

    isWithoutPaymentFormValid() {
        let valid = true;
        if (!this.fullName || !this.phoneNumber) {
            valid = false;
        }
        return valid;
    }

    onNameChange(name) {
      this.fName = name;
    }

    onFullNameChange(name) {
      this.fullName = name;
    }

    onPhoneNumberChange(pNumber) {
      this.phoneNumber = pNumber;
    }

    onEmailChange(email) {
      this.email = email;
    }

    onLNameChange(lastName) {
      this.lName = lastName;
    }

    onAddressChange(address) {
     this.address = address;
    }

    onCityChange(city) {
      this.city = city;
    }

    onStateChange(state) {
      this.state = state;
    }

    onZipChange(zip) {
      this.zip = zip;
    }

    public sendDataToPos(paymentDetails) {
        this.checkoutLoading = true;
        const order = {
            id: null,
            organizationId: this.organizationId,
            name: this.fName ? this.fName + ' ' + this.lName : this.fullName,
            email: this.email ? this.email : null,
            phoneNumber: this.phoneNumber ? this.phoneNumber : null,
            address: this.address + ', ' + this.city + ', ' + this.state + ' ' + this.zip ,
            delivery: this.delivery,
            shipping: this.shipping,
            shippingAmount: this.shippingAmount,
            onlineShoppingCartItems: this.shoppingCartItems.map((items) => {
                return {
                    id: null,
                    organizationId: this.organizationId,
                    quantity: items.quantity,
                    menuItemId: items.id,
                    price: items.price,
                    taxRate: items.taxRate,
                    updated: this.todayDate,
                    notes: null
                };
            }),
            updated: this.todayDate,
            orderNumber: paymentDetails ? paymentDetails.orderNumber : null,
            fulfillmentTime: 30,
            tipAmount: this.selectedTip
        };
        this.checkoutService.sendOrderInformation(order).subscribe((test) => {
          this._shared.setLoader(false);
            if (test) {
                this.checkoutLoading = false;
                this.orderConformation.emit(true);
                this.checkoutService.storeOrderNumber(test.orderNumber);
                this.checkoutService.storeOrderInfo(test);
            }
        }, (error) => {
          this._shared.setLoader(false);
        });
    }
}
