import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import {HttpClient} from '@angular/common/http';
// import {of} from 'rxjs/observable/of';

// key that is used to access the data in local storageconst
const STORAGE_KEY = 'local_todolist';

@Injectable ()
export class ShoppingCartService {
    private itemsInCartSubject: BehaviorSubject<any[]> = new BehaviorSubject (this.storage.get (STORAGE_KEY) || []);
    private itemsInCart: any[] = [];
    // TODO: being button service
    public addToCartButtonId: boolean;


    constructor(@Inject (LOCAL_STORAGE) private storage: StorageService, private http: HttpClient) {
        this.itemsInCartSubject.subscribe (_ => this.itemsInCart = _);
    }

    public getItems(): Observable<any[]> {
        return this.itemsInCartSubject;
    }

    public removeFromCart(item: any) {
        const currentItems = [...this.itemsInCart];
        const itemsWithoutRemoved = currentItems.filter (_ => _.id !== item.id);
        this.storage.set (STORAGE_KEY, itemsWithoutRemoved);
        this.itemsInCartSubject.next (this.storage.get (STORAGE_KEY) || []);
    }

    public getShippingCharges(organizationId) {
        return this.http.get (`https://erestaupos.kapava.com/restaurantsolutions/public/api/shippingfees/${organizationId}`);
    }

    public removeAllFromCart() {
        this.storage.remove (STORAGE_KEY);
        this.itemsInCartSubject.next ([]);
    }

    public storeOnLocalStorage(addedItem): void {
        addedItem.quantity = 1;
        this.addToCartButtonId = addedItem.id;
        addedItem.finalPrice = addedItem.price;
        // get array of tasks from local storage
        const currentItemList = this.storage.get (STORAGE_KEY) || [];
        if ( currentItemList.length < 1 ) {
            // push new task to array
            currentItemList.push (addedItem);
            // insert updated array to local storage
            this.storage.set (STORAGE_KEY, currentItemList);
            this.itemsInCartSubject.next (currentItemList);
        } else {
            this.checkForDuplicateItem (addedItem);
        }
    }

    removeSingleItem(addedItem) {
        const updatedList = this.storage.get (STORAGE_KEY) || [];
        updatedList.map ((currentItem) => {
            if ( currentItem.id === addedItem.id ) {
                if (currentItem.quantity > 1) {
                    currentItem.quantity -= 1;
                    currentItem.finalPrice = ( currentItem.price * currentItem.quantity );
                } else {
                    currentItem.quantity = 0;
                    currentItem.finalPrice = ( currentItem.price * currentItem.quantity );
                }
            }
        });
        let updatedListFinal = updatedList.filter((item) => item.quantity != 0);
        const unique = updatedListFinal.filter ((e, i) => updatedListFinal.findIndex (a => a.id === e.id) === i);
        this.storage.set (STORAGE_KEY, unique);
        this.itemsInCartSubject.next (this.storage.get (STORAGE_KEY));
    }

    checkForDuplicateItem(addedItem) {
        const updatedList = this.storage.get (STORAGE_KEY) || [];
        updatedList.map ((currentItem) => {
            if ( currentItem.id === addedItem.id ) {
                currentItem.quantity += 1;
                currentItem.finalPrice = ( currentItem.price * currentItem.quantity );
            }
        });
        updatedList.push (addedItem);
        const unique = updatedList.filter ((e, i) => updatedList.findIndex (a => a.id === e.id) === i);
        this.storage.set (STORAGE_KEY, unique);
        this.itemsInCartSubject.next (this.storage.get (STORAGE_KEY));
    }

    onQtyChange(value, selectedItem) {
        const cartItems = this.storage.get (STORAGE_KEY);
        cartItems.map ((item) => {
            if ( item.id === selectedItem.id ) {
                item.quantity = value;
                item.finalPrice = ( item.price * item.quantity );
            }
        });
        this.storage.set (STORAGE_KEY, cartItems);
        this.itemsInCartSubject.next (this.storage.get (STORAGE_KEY));
    }

    // TODO: global update for product buttons (across menu and dialog)
    updateButtonForProduct(selectedProduct) {
        return true;
    }
}
