<div class="page-container">
    <div class="home-page">
        <div class="page-title">
            <h2>{{ groceryStores.length }} Grocery Shop</h2>
            <div class="page-filter">
                <ul>
                    <li [ngClass]="{'active': sortType == 'delivery'}" (click)="sortProduct('delivery')"><span>Order for Delivery</span></li>
                    <li [ngClass]="{'active': sortType == 'pickup'}" (click)="sortProduct('pickup')"><span>Order for Pickup</span></li>
                </ul>
                <span class="filter-trigger">
                    <i class="fas fa-filter"></i> Filter
                </span>
            </div>
        </div>
        <div class="store-list">
            <div class="row space-50 space-sm-20">
                <div class="col-md-4" *ngFor="let organization of groceryStores">
                    <div class="store-item" (click)="onGoToMenuClick(organization)">
                        <div class="store-item-hover"></div>
                        <div class="store-location-retriving" *ngIf="organization.deliveryAvailable && !webwork.location">
                            <i class="fas fa-search-location animate__animated animate__shakeY animate__infinite animate__slower"></i> <br>
                            <p>Getting your location to see if we can deliver to your home or office...</p>
                        </div>
                        <div class="store-item-image"> 
                            <app-store-image [imageUrl]="organization.logoUrl" [isAvailable]="organization.deliveryAvailable"></app-store-image>
                            <!-- <span class="shop-status" *ngIf="!organization.deliveryAvailable">
                                Order For Pickup
                            </span>
                            <span class="shop-status blue-status" *ngIf="organization.deliveryAvailable">
                                Order For Delivery
                            </span> -->
                            <!-- <span class="shop-status red-status" *ngIf="!organization.deliveryAvailable">
                                Not Available
                            </span> -->
                            <!-- <img src="/assets/img/sample-product-image.jpg" /> -->
                            <!-- <app-image [organization]="organization"></app-image> -->
                        </div>
                        <div class="store-item-body">
                            <h2>{{organization.name}}</h2>
                            <p>
                                {{organization.address.street}}<br/>
                                {{organization.address.city}} {{organization.address.state}}
                            </p>
                            <!-- <ng-container *ngIf="organization.deliveryAvailable">
                                <ng-container *ngIf="!webwork.location">
                                    <p>
                                    ...<br/>
                                    Getting your location to see if we can deliver to your home or office...
                                    </p>
                                </ng-container>
                            </ng-container> -->
                            <!-- <ng-container *ngIf="organization.deliveryAvailable">
                                <ng-container *ngIf="!webwork.location">
                                    ...<br/>
                                    Getting your location to see if we can deliver to your home or office...
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!organization.deliveryAvailable">
                                <mat-card-actions fxLayoutAlign="center">
                                    <button (click)="onGoToMenuClick(organization)" mat-raised-button>ORDER FOR PICKUP</button>
                                </mat-card-actions>
                            </ng-container>
                            <ng-container *ngIf="organization.deliveryAvailable">
                                <ng-container *ngIf="webwork.location">
                                    <mat-card-actions fxLayoutAlign="center">
                                        <button (click)="onGoToMenuClick(organization)" mat-raised-button>ORDER FOR DELIVERY</button>
                                    </mat-card-actions>
                                </ng-container>
                            </ng-container>
                
                            <ng-container *ngIf="organization.deliveryAvailable">
                                <div *ngIf="debug" class="debug">
                                    Restaurant Location
                                    {{organization.address.latitude}},{{organization.address.longitude}}
                                    <hr/>
                                    <ng-container *ngIf="webwork.location">
                                        Your Location
                                        {{webwork.location.latitude}},{{webwork.location.longitude}}
                
                                        <br/>
                                        <hr/>
                                        Delivery Radius: <span>{{getKlm(organization.id)}}</span> miles
                                        <button (click)="checkDeliveryStatus(organization.address.latitude,organization.address.longitude,webwork.location.latitude,webwork.location.longitude)" mat-raised-button
                                                type="submit">Check for delivery
                                        </button>
                                        <br/>
                                        {{distanceInfo | json}}
                                        <br/>
                                        <ng-container *ngIf="webwork.deliveryProximity">
                                            <ng-container *ngIf="deliverableToCustomer">
                                                Available for delivery: order now
                                            </ng-container>
                                            <ng-container *ngIf="!deliverableToCustomer">
                                                Unfortunately, your location is not available for delivery.
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container> -->
                            <app-store-star [star]="4.8"></app-store-star>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- <div class="home-container" (window:resize)="onResize()" *ngIf="isMobile ? isMobile : false">
    <div>
        <app-restaurants-search [organizations]="organizations"></app-restaurants-search>
    </div>
    <div class="home-card-container">
        <mat-card *ngFor="let organization of groceryStores"
                  class="home-card-margin"
                  fxLayout="row" fxLayoutAlign="space-between center"
                  (click)="onGoToMenuClick(organization)">

            <div>
                <h1>
                    {{organization.name}}
                </h1>
                <p>
                    {{organization.address.street}}
                    <br/>{{organization.address.city}}
                    {{organization.address.state}}
                </p>
                
            </div>
            <div class="home-card-image-container">
                <app-image [organization]="organization"></app-image>
            </div>

        </mat-card>
    </div>
</div> -->
<!-- 
<div class="home-container" (window:resize)="onResize()" *ngIf="!isMobile">
    <div>
        <app-restaurants-search [organizations]="organizations"></app-restaurants-search>
    </div>
    <div class="row home-card-container" style="display:flex;position:relative;flex-wrap:wrap;justify-content:flex-start;">
        <mat-card *ngFor="let organization of groceryStores" class="col-sm-2 home-card-margin">
            <div>
                <app-image [centerImage]="true" [organization]="organization"></app-image>
            </div>
            <mat-card-content>
                <h1 class="retail-card-h1">
                    {{organization.name}}
                </h1>
                <p class="retail-card-p">
                    {{organization.address.street}}
                    <br/>{{organization.address.city}}
                    {{organization.address.state}}
                </p>
                <ng-container *ngIf="organization.deliveryAvailable">
                    <ng-container *ngIf="!webwork.location">
                        ...<br/>
                        Getting your location to see if we can deliver to your home or office...
                    </ng-container>
                </ng-container>
            </mat-card-content>

            <ng-container *ngIf="!organization.deliveryAvailable">
                <mat-card-actions fxLayoutAlign="center">
                    <button (click)="onGoToMenuClick(organization)" mat-raised-button>ORDER FOR PICKUP</button>
                </mat-card-actions>
            </ng-container>
            <ng-container *ngIf="organization.deliveryAvailable">
                <ng-container *ngIf="webwork.location">
                    <mat-card-actions fxLayoutAlign="center">
                        <button (click)="onGoToMenuClick(organization)" mat-raised-button>ORDER FOR DELIVERY</button>
                    </mat-card-actions>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="organization.deliveryAvailable">
                <div *ngIf="debug" class="debug">
                    Restaurant Location
                    {{organization.address.latitude}},{{organization.address.longitude}}
                    <hr/>
                    <ng-container *ngIf="webwork.location">
                        Your Location
                        {{webwork.location.latitude}},{{webwork.location.longitude}}

                        <br/>
                        <hr/>
                        Delivery Radius: <span>{{getKlm(organization.id)}}</span> miles
                        <button (click)="checkDeliveryStatus(organization.address.latitude,organization.address.longitude,webwork.location.latitude,webwork.location.longitude)" mat-raised-button
                                type="submit">Check for delivery
                        </button>
                        <br/>
                        {{distanceInfo | json}}
                        <br/>
                        <ng-container *ngIf="webwork.deliveryProximity">
                            <ng-container *ngIf="deliverableToCustomer">
                                Available for delivery: order now
                            </ng-container>
                            <ng-container *ngIf="!deliverableToCustomer">
                                Unfortunately, your location is not available for delivery.
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

        </mat-card>
    </div>
</div> -->
