import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectedProductDialogComponent } from '../../selected-product-dialog/selected-product-dialog.component';
import { ResponsiveService } from '../../../services/responsive.service';
import { Router } from '@angular/router';
import { ShoppingCartService } from '../../../services/shopping-cart.service';

@Component ({
    selector: 'app-card',
    templateUrl: 'card.component.html',
    styleUrls: ['card.component.scss']
})

export class CardComponent implements OnInit {
    @Input () public productName;
    @Input () public products;
    @Input () public currentIndex;
    public selectedProduct;
    public productAdded: Boolean;
    public cartItems: any;
    public currentQuanity: any = 0;

    constructor(public dialog: MatDialog,
                private router: Router,
                private cartService: ShoppingCartService) {
    }

    ngOnInit() {
        this.cartService.getItems().subscribe((response) => {
            this.cartItems = response;
            this.currentQuanity = this.cartItems.find(item => item.id == this.products.id)?.quantity;
            if (this.currentQuanity == 0) {
                this.productAdded = false;
            }
        });
    }

    onAddToCartClick(selectedProduct) {
        this.cartService.storeOnLocalStorage (selectedProduct);
        this.productAdded = true;
    }

    updateButtonForProduct(selectedProduct) {
        this.cartService.storeOnLocalStorage (selectedProduct);
        this.productAdded = true;
    }

    removeProduct(selectedProduct) {
        this.cartService.removeSingleItem(selectedProduct);
    }
}

