<!--<script src="https://secure.networkmerchants.com/token/Collect.js" data-tokenization-key="5mN8N7-jhr55W-N22pxX-uAW2s9"></script>-->
<!--TODO: clean up form and only request required data-->
<!-- <h4 class="page-title"><span>Checkout Form</span></h4> -->
<!-- <div *ngIf="checkoutLoading" class="loading-container">
    <mat-spinner class="loading"></mat-spinner>
</div> -->
<form action="/erestau/directPost" method="post" (ngSubmit)="loadScript()" *ngIf="!checkoutLoading">
    <div class="form-row">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <input type="text" (keyup)="onNameChange($event.target.value)" class="form-control" placeholder="First Name" name="fname">
            </div>
            <div class="col-md-12 col-lg-6">
                <input type="text" (keyup)="onLNameChange($event.target.value)" class="form-control" placeholder="Last Name" name="lname">
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <input type="text" (keyup)="onAddressChange($event.target.value)" class="form-control" placeholder="Street Address" name="address1">
            </div>
            <div class="col-md-12 col-lg-6">
                <input type="text" (keyup)="onCityChange($event.target.value)" class="form-control" placeholder="City" name="city">
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <input type="state" (keyup)="onStateChange($event.target.value)" class="form-control" placeholder="State" name="state">
            </div>
            <div class="col-md-12 col-lg-6">
                <input type="text" (keyup)="onZipChange($event.target.value)" class="form-control" placeholder="Zip code" name="zip">
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <input type="number" (keyup)="onPhoneNumberChange($event.target.value)" class="form-control" placeholder="Phone Number" name="phoneNumber">
            </div>
            <div class="col-md-12 col-lg-6">
                <input type="text" (keyup)="onEmailChange($event.target.value)" class="form-control" placeholder="Email (Optional)" name="email">
            </div>
        </div>
    </div>
    <!-- <div class="formInner">
        <div class="form-group">
            <input type="text" (keyup)="onNameChange($event.target.value)" class="form-control" placeholder="First Name" name="fname">
        </div>
        <div class="form-group">
            <input type="text" (keyup)="onLNameChange($event.target.value)" class="form-control" placeholder="Last Name" name="lname">
        </div>
        <div class="form-group" *ngIf="delivery || shipping">
            <input type="text" (keyup)="onAddressChange($event.target.value)" class="form-control" placeholder="Street Address" name="address1">
        </div>
        <div class="form-group" *ngIf="delivery || shipping">
            <input type="text" (keyup)="onCityChange($event.target.value)" class="form-control" placeholder="City" name="city">
        </div>
        <div class="form-group" *ngIf="delivery || shipping">
            <input type="state" (keyup)="onStateChange($event.target.value)" class="form-control" placeholder="State" name="state">
        </div>
        <div class="form-group" *ngIf="delivery || shipping">
            <input type="text" (keyup)="onZipChange($event.target.value)" class="form-control" placeholder="Zip code" name="zip">
        </div>
        <div class="form-group">
            <input type="number" (keyup)="onPhoneNumberChange($event.target.value)" class="form-control" placeholder="Phone Number" name="phoneNumber">
        </div>
        <div class="form-group">
            <input type="text" (keyup)="onEmailChange($event.target.value)" class="form-control" placeholder="Email (Optional)" name="email">
        </div>
    </div> -->
    <input style="display: none;" [disabled]="!isFormValid()" type="submit" id="payButton" value="Pay {{totalAmount ? totalAmount.toFixed(2) : 0}}" class="btn btn-primary btn-block">
</form>
<!-- <div id="paymentTokenInfo"></div> -->

<!--<form class="check-out-form" (ngSubmit)="onSubmitWithoutPayment()" *ngIf="!displayPamentCheckOut && !checkoutLoading">-->
<!--    <div class="formInner">-->
<!--        <div class="form-group">-->
<!--            <input type="text" (keyup)="onFullNameChange($event.target.value)" class="form-control" placeholder="Name">-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--            <input type="text" (keyup)="onPhoneNumberChange($event.target.value)" class="form-control" placeholder="Phone Number">-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--            <input type="text" (keyup)="onEmailChange($event.target.value)" class="form-control" placeholder="Email (Optional)">-->
<!--        </div>-->
<!--    </div>-->
<!--    <input [disabled]="!isWithoutPaymentFormValid()" type="submit" value="Submit" class="btn btn-primary btn-block">-->
<!--</form>-->
