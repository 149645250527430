import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { Constants } from '../components/module/Constants';

@Injectable()
export class CheckoutService {
    private orderNumber: BehaviorSubject<any> = new BehaviorSubject(0);
    private isShippingOrder: BehaviorSubject<any> = new BehaviorSubject(false);
    private orderInfo: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(private http: HttpClient) {
    }

    public sendOrderInformation(item): Observable<any> {
        const apiPath = Constants.ERESTAU_DOMAIN + '/public/api/onlineorders';
        return this.http.post(apiPath, item);
    }

    public sendToken(token, totalAmount, organizationId): Observable<any> {
        const apiPath = Constants.ERESTAU_DOMAIN + '/public/api/payment?token=' + `${token.token}` + '&amount=' + `${totalAmount}` + '&organizationId=' + `${organizationId}`;
        // console.log('Sending payment request....');
        return this.http.get(apiPath);
    }

    public getDataTokenizationKey(organizationId) {
        const apiPath = Constants.ERESTAU_DOMAIN + `/public/api/merchantdata/${organizationId}`;
        return this.http.get(apiPath);
    }

    public storeOrderNumber(orderNumber): void {
        this.orderNumber.next(orderNumber);
    }

    public getOrderNumber() {
        return this.orderNumber;
    }

    public storeOrderInfo(orderInfo): void {
        this.orderInfo.next(orderInfo);
    }

    public getOrderInfo() {
        return this.orderInfo;
    }

    public storeShippingBoolean(shipping): void {
        this.isShippingOrder.next(shipping);
    }

    public getShippingInfo() {
        return this.isShippingOrder;
    }
}
