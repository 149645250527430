
<div class="store-view" *ngIf="selectedStore">
    <div class="page-container">
        <div class="store-container">
            <div class="store-snippet">
                <div class="store-snippet-image">
                    <app-store-image [imageUrl]="selectedStore.logoUrl" [isAvailable]="true"></app-store-image>
                </div>
                <div class="store-snippet-content">
                    <h2>{{ selectedStore.name }}</h2>
                    <p>
                        {{selectedStore.address.street}}<br/>
                        {{selectedStore.address.city}} {{selectedStore.address.state}}
                    </p>
                    <app-store-star style="padding-right:20px;" [star]="4.8"></app-store-star>
                    <!-- <app-store-discount [discount]="10"></app-store-discount> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-container">
    <div class="store-product-header">
        <div class="category-trigger" *ngIf="toggleSearch || !mobileView" (click)="toggleSide()">
            <i class="fas fa-bars"></i> <h3 *ngIf="!selectedCategory">Category</h3><h3 *ngIf="selectedCategory">{{ selectedCategory }}</h3>
        </div>
        <div class="store-product-search">
            <div class="search-icon" (click)="expandSearch()" *ngIf="mobileView && toggleSearch">
                <i class="fas fa-search"></i>
            </div>
            <div class="store-product-search-box" *ngIf="!toggleSearch">
                <div class="search-box">
                    <input type="text" [(ngModel)]="filterValue" /> <i class="fas fa-search"></i>
                </div>
                <i *ngIf="mobileView" (click)="hideExpandSearch()" class="far fa-times-circle"></i>
            </div>
        </div>
    </div>
    <div class="store-product-body">
        <!-- <div class="store-product-category">
            <div class="sidenav-list">
                <ul>
                    <li [ngClass]="{'active': tab.isActive}" (click)="onTabChange(tab)" *ngFor="let tab of categoryName">{{tab.name}}</li>
                </ul>
            </div>
        </div>
        <div class="store-product-list"> -->
            <div class="store-list">
                <div class="row space-50 space-md-20">
                    <div class="col-md-4 col-lg-3" *ngFor="let products of productsByCategory | arrayFilter: filterValue: 'name'; let index = index;">
                        <app-card
                            [productName]="products.name"
                            [products]="products" [currentIndex]="index">
                        </app-card>
                        <!-- <div class="store-item">
                            <div class="store-item-image">
                                <app-store-image 
                                [imageUrl]="'/assets/img/sample-product-image.jpg'" 
                                [ratting]="'3.4'" 
                                [foodType]="'nonVeg'"
                                ></app-store-image>
                            </div>
                            <div class="store-item-body">
                                <h2>{{products.name}}</h2>
                                <p>{{products.description}}</p>
                                <div class="price-row">
                                    <label>{{products.price | currency}}</label>
                                    <button class="btn btn-green">
                                        <i class="fas fa-plus"></i> ADD
                                    </button>
                                    <button class="btn btn-green-border" style="cursor: auto;">
                                        <i class="fas fa-minus" style="cursor: pointer;"></i><span style="padding: 0 10px;">2</span><i class="fas fa-plus" style="cursor: pointer;"></i>
                                    </button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        <!-- </div> -->
    </div>
</div>

<!--{{paramsId}}-->
<!-- <div (window:resize)="onResize()">
    <mat-tab-group (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngFor="let tab of categoryName" [label]=tab.name>
            <div [ngClass]="{'row' : !isMobile}">
                <app-card
                        *ngFor="let products of productsByCategory"
                        [ngClass]="{'col-lg-2 noPadding' : !isMobile}"
                        [productName]="products.name"
                        [products]="products" class="col-lg-2 noPadding"></app-card>
            </div>
        </mat-tab>
    </mat-tab-group>
</div> -->

