import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {ResponsiveService} from '../../services/responsive.service';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: 'confirmation-dialog.component.html',
    styleUrls: ['confirmation-dialog.component.scss']
})

export class ConfirmationDialogComponent implements OnInit {
    public isMobile: Boolean;

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
                private responsiveService: ResponsiveService,
                private cartService: ShoppingCartService,
                private router: Router) {}
    ngOnInit() {
        this.onResize();
        this.responsiveService.checkWidth();
    }

    onResize() {
        this.responsiveService.getMobileStatus().subscribe(isMobile => {
            this.isMobile = isMobile;
        });
    }

    onNoClick() {
        this.dialogRef.close();
    }

    onYesClick() {
        this.cartService.removeAllFromCart();
        this.dialogRef.close();
        this.router.navigateByUrl('/home');
    }
}
