import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayFilter'
})

export class FilterPipe implements PipeTransform {
    transform(value: any, filterValue: any, property: any): any {
        return value.filter((data) => {
            return data[property].toLowerCase().includes(filterValue.toLowerCase());
        });
    }
}