import { Component, OnInit, HostListener } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { uniq } from 'ramda';
import { ResponsiveService } from '../../services/responsive.service';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../../services/home.service';
import { environment } from '../../../environments/environment';
import { DebugService } from '../../services/debug.service';
import { WebworkService } from '../../services/webwork.service';
import { SidenavService } from '../../services/sidenav.service';
import { SharedService } from '../../services/shared.service';

@Component ({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss']
})

export class MenuComponent implements OnInit {
    public menuJson: any;
    public menuData = [];
    public categoryName = [];
    public category = [];
    public productsByCategory = [];
    public isMobile: boolean;
    public paramsId;
    public sideNavToggle: boolean;
    public filterValue: string = "";
    public mobileView: boolean = false;
    public toggleSearch: boolean = false;
    public selectedCategory: any = null;
    public selectedStore: any = null;

    constructor(
        private menuService: MenuService,
        private responsiveService: ResponsiveService,
        private activatedRoute: ActivatedRoute,
        private debug: DebugService,
        private homeService: HomeService,
        private webworkService: WebworkService,
        private _sideNav: SidenavService,
        private _shared: SharedService
    ) {
    }

    ngOnInit() {
        // Subscribe Side Flag
        this._sideNav.toggleSideNav.subscribe((response) => {
            this.sideNavToggle = response;
        });

        // Subscribe Category Change
        this._sideNav.selectedCategory.subscribe((response) => {
            this.selectedCategory = response?.name;
            this.onTabChange(response.name);
        })

        // Set Loader
        setTimeout(() => {
            this._shared.setLoader(true);
        }, 0);
        

        this.paramsId = this.activatedRoute.snapshot.params.alias;
        this.menuJson = this.menuService.getMenuDetailsByAlias (this.paramsId).subscribe ((data) => {
            this.menuData = data;
            this.getCategoryName (this.menuData);
            //this.getProductByCategory (this.menuData);
        });
        this.onResize ();
        this.responsiveService.checkWidth ();
        this.homeService.getOrganization().subscribe((selectedOrganization) => {
            this.selectedStore = selectedOrganization;
            // console.log(this.selectedStore);
            if (!selectedOrganization ) {
                this.homeService.getOrganizations().subscribe((allAddresses) => {
                    allAddresses.map((address) => {
                        if (address.alias === this.paramsId) {
                            this.homeService.storeOrganization(address);
                            this.selectedStore = address;
                            // console.log(this.selectedStore);
                        }
                    });
                });
            }
        });
    }

    expandSearch() {
        this.toggleSearch = false;
    }

    hideExpandSearch() {
        this.toggleSearch = true;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        const width = window.innerWidth;
        if (width <= 767) {
            this.mobileView = true;
            this.toggleSearch = true;
        } else {
            this.mobileView = false;
            this.toggleSearch = false;
        }

        this.responsiveService.getMobileStatus ().subscribe (isMobile => {
            this.isMobile = isMobile;
        });
    }

    getCategoryName(category) {
        category.map ((names) => {
            this.category.push (names.category);
        });
        this.category.map (categoryName => {
            this.categoryName.push (categoryName.name);
        });
        this.categoryName = uniq (this.categoryName);
        this.categoryName = this.categoryName.map((item) => {
            return {
                name: item,
                isActive: false
            }
        })
        if (this.categoryName.length > 0) {
            this.categoryName[0].isActive = true;
            this._sideNav.setSideNavData(this.categoryName);
            this.onTabChange(this.categoryName[0].name);
        } else {
            this._shared.setLoader(false);
        }
        
    }

    toggleSide() {
        this.sideNavToggle = !this.sideNavToggle;
        this._sideNav.setToggleSideNav(this.sideNavToggle);
    }

    getProductByCategory(dataItem) {
        dataItem.forEach (product => {
            if ( product.category.name === this.categoryName[0].name ) {
                this.productsByCategory.push (product);
            }
        });
        this._shared.setLoader(false);
    }

    // onTabChange($event) {
    //     this.productsByCategory = [];
    //     this.menuData.forEach (product => {
    //         if ( product.category.name === $event.tab.textLabel ) {
    //             this.productsByCategory.push (product);
    //         }
    //     });
    // }

    onTabChange(tabName) {
        this._shared.setLoader(true);

        this.productsByCategory = [];
        this.menuData.forEach (product => {
            if ( product.category.name === tabName ) {
                this.productsByCategory.push (product);
            }
        });

        this._shared.setLoader(false);
    }
}
