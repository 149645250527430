import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResponsiveService } from '../services/responsive.service';
import { SharedService } from '../services/shared.service';
import { SidenavService } from '../services/sidenav.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component ({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'restaurantApp';
    public isMobile: boolean;
    public globalLoader: boolean;
    public appSubscription = new Subscription();
    public pageTitle: string = null;
    public toggleSideNav: boolean;
    public sideNavData: any;

    constructor(
        private responsiveService: ResponsiveService, 
        public _shared: SharedService, 
        private _route: ActivatedRoute, 
        private _router: Router,
        private _sideNav: SidenavService
    ) {
        
    }

    ngOnInit() {
        this.onResize ();
        this.responsiveService.checkWidth ();

        this._router.events.subscribe(event => {
            if(event instanceof NavigationEnd) {
                this.pageTitle = this._route.root.firstChild.snapshot.data['title'];
                // console.log(this.pageTitle);
            }
        });

        // Subscribe Loader
        const loaderSubscribe = this._shared.globalLoader.subscribe((flag) => {
            this.globalLoader = flag;
        });
        

        // Observe SideNav Data
        const toggleSideNavSubscribe = this._sideNav.toggleSideNav.subscribe((response) => {
            this.toggleSideNav = response;
        });
        const toggleSideNavDataSubscribe = this._sideNav.sideNavData.subscribe((response) => {
            this.sideNavData = response;
        })

        this.appSubscription.add(loaderSubscribe);
        this.appSubscription.add(toggleSideNavSubscribe);
        this.appSubscription.add(toggleSideNavDataSubscribe);
    }

    onResize() {
        this.responsiveService.getMobileStatus ().subscribe (isMobile => {
            this.isMobile = isMobile;
        });
    }

    ngOnDestroy() {
        this.appSubscription.unsubscribe();
    }
}
