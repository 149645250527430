
    <div class="page-container">
        <div class="header-container">
            <div class="logo">
                <a (click)="this.logoService.resetContext()" routerLink="/"><img src="/assets/img/logo_new.png" alt="" /></a>
            </div>
            <div class="nav-bar">
                <i class="fas fa-bars"></i>
            </div>
            <div class="search-bar" *ngIf="searchVisible">
                <app-restaurants-search [organizations]="organizations"></app-restaurants-search>
                <span class="close-search" (click)="closeSearch()">
                    <i class="far fa-times-circle"></i>
                </span>
            </div>
            <div class="user-cart">
                <ul>
                    <li class="user-nav"><a href="#"><i class="far fa-user"></i><b>Login</b></a></li>
                    <li class="mobile-search" *ngIf="!searchVisible" (click)="openSearch()"><i class="fas fa-search"></i></li>
                    <li (click)="onShoppingCartClick()"><a href="javascript:void(0)"><i class="fas fa-shopping-cart"></i><span class="cart-count">{{totalItemsInCart}}</span><b>Cart</b></a></li>
                </ul>
            </div>
        </div>
    </div>



<!-- <div (window:resize)="onResize()" [ngClass]="{'mobile-header-container': isMobile ? isMobile : false, 'header-container': !isMobile}" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row"> -->
        <!-- <a (click)="this.logoService.resetContext()" routerLink="/" *ngIf="!isMobile">
            <img [src]="logoService.pageData" class="logo-img"/>
        </a>
        <a (click)="this.logoService.resetContext()" routerLink="/" *ngIf="isMobile">
            <img [src]="logoService.pageData" class="logo-img-mobile"/>
        </a> -->
        <!-- <div *ngIf="organizationAlias && organizationAlias.organization" fxLayout="column">
            <div style="margin-left: 20px; font-weight: bold;">
                Delivery Hours for {{organizationAlias.organization.name}}
            </div>
            <div style="color: #a6da7d; margin-left: 65px; font-weight: bold;">
                {{deliveryHours}}
            </div>
            <span *ngIf="!locationData">{{locationData | json}}</span>
        </div>
    </div> -->
    <!-- <mat-toolbar *ngIf="!isMobile" class="top-toolbar-container">
        <mat-toolbar-row>
      <span *ngFor="let topTab of headerTab let i = index" class="tab-container">
        <div (click)="changeTopTab(i)" [ngClass]="{'active-top-tab': topTab.active, 'disable-tab': topTab.disable}" class="header-tabs">
          {{topTab.label}}
        </div>
      </span>
        </mat-toolbar-row>
    </mat-toolbar> -->
    <!-- <div *ngIf="!isMobile" class="header-buttons">
        <div *ngIf="totalItemsInCart > 0"
             class="shopping-cart-badge">
            {{totalItemsInCart}}
        </div>
        <button (click)="onShoppingCartClick()" class="shopping-icon" mat-icon-button><i class="material-icons">shopping_cart</i></button>
    </div> -->
<!-- </div> -->
