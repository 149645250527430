<mat-toolbar class="mobile-footer-container">
    <div class="flex-container" fxLayout="row" fxLayoutAlign="space-around center">
        <ng-container *ngFor="let tab of footerTab; let i = index">
            <span>
                <div (click)='changeTab(i)'
                   [ngClass]="{'active-tab': tab.active, 'disable-footer-tab': tab.disable}">{{tab.label}} {{tab.label === 'Checkout' ? '(' + totalItemsInCart + ')' : ''}}
                </div>
            </span>
        </ng-container>
    </div>
</mat-toolbar>