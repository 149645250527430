import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MenuComponent} from './menu/menu.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {OrderConfirmationComponent} from './order-confirmation/order-confirmation.component';
import {RetailHomeComponent} from './retail-home/retail-home.component';
import { PaymentSummaryComponent } from './payment-summary/payment-summary.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { title : "homepage"} },
  { path: 'grocery', component: RetailHomeComponent, data: { title : "homepage"} },
  { path: 'menu/:alias', component: MenuComponent, data: { title : "menu"} },
  { path: 'shopping-cart', component: ShoppingCartComponent, data: { title : "shoppingcart"} },
  { path: 'paymentSummary', component: PaymentSummaryComponent, data: { title : "paymentSummary"} },
  { path: 'order-confirmation', component: OrderConfirmationComponent, data: { title : "orderconfirmation"} },
  { path: '', redirectTo: 'home', pathMatch: 'full', data: { title : ""} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
