<div [ngClass]="{'large-view': menuItem}" class="card-image" *ngIf="!organization" >
    <img (click)="onGoToMenuClick(organization)" *ngIf="organization" [src]="organization.logoUrl " class="organization-image"/>
    <img *ngIf="product" [alt]="product.name" [src]="product.imageUrl" [title]="product.name" class="product-image"/>
    <img *ngIf="menuItem" [alt]="menuItem.name" [src]="menuItem.imageUrl" [title]="menuItem.name" class="menu-add-image"/>
    <img *ngIf="cartItem" [alt]="cartItem.name" [src]="cartItem.imageUrl" [title]="cartItem.name" class="cart-image"/>
    <img *ngIf="!product && !organization && !cartItem && !menuItem && !imgPath" alt="Image not available" src="https://placehold.it/200x100"/>
</div>

<div [ngClass]="{'large-view': menuItem}" class="restaurant-card-image" *ngIf="organization && !centerImage" >
    <img (click)="onGoToMenuClick(organization)" *ngIf="organization" [src]="organization.logoUrl " class="organization-image"/>
</div>

<div [ngClass]="{'large-view': menuItem}" class="card-image" *ngIf="organization && centerImage" >
    <img (click)="onGoToMenuClick(organization)" *ngIf="organization" [src]="organization.logoUrl " class="organization-image"/>
</div>

