<div class="page-container">
    <div class="shopping-cart-page">
        <div class="page-title">
            <h2>Your Current Orders</h2>
        </div>
        <div class="cart-list" *ngIf="shoppingCartItems.length > 0">
            <div class="cart-list-row" *ngFor="let item of shoppingCartItems">
                <div class="cart-item">
                    <div class="cart-image">
                        <app-store-image 
                        [imageUrl]="item.imageUrl"
                        ></app-store-image>
                    </div>
                    <div class="cart-details">
                        <h3>{{item.name}}</h3>
                        <p>{{item.description}}</p>
                    </div>
                </div>
                <div class="cart-price">
                    <label>${{item.finalPrice.toFixed(2)}}</label>
                </div>
                <div class="cart-quantity">
                    <button class="btn btn-green-border" style="cursor: auto;">
                        <i (click)="getTheQtyEntered('decriment', item)" class="fas fa-minus" style="cursor: pointer;"></i>
                        <span style="padding: 0 10px;">{{ item.quantity }}</span>
                        <i (click)="getTheQtyEntered('increment', item)" class="fas fa-plus" style="cursor: pointer;"></i>
                    </button>
                </div>
                <div class="cart-delete">
                    <span (click)="removeItem(item)"><i class="fas fa-trash-alt"></i></span>
                </div>
            </div>
            <div class="cart-list-footer">
                <div class="cart-total">
                    <label>Total:</label>
                    <span>${{cartTotal.toFixed(2)}}</span>
                </div>
                <div class="cart-button">
                    <button class="btn btn-green" (click)="goToPaymentSummary()">Checkout</button>
                </div>
            </div>
        </div>
        <div class="no-cart-list" *ngIf="shoppingCartItems.length <= 0">
            <div class="no-cart-content">
                <img alt="Your cart is empty" src="../assets/img/emptycart.png" title="Your cart is empty"/>
                <h3 [ngClass]="{'cart-message': !isMobile, 'mobile-cart-message': isMobile}">You have 0 items in shopping cart</h3>
                <button class="btn btn-green" routerLink="/">Continue Ordering</button>
            </div>
        </div>
    </div>  
</div>

<!-- 
<div (window:resize)="onResize()" class="shopping-cart-card">
    <div *ngIf="!isMobile" fxLayout="row">
        <div fxFlex="100">
            <div *ngIf="shoppingCartItems.length > 0" class="tip-component" fxLayoutAlign="end">
                <label class="tip-label">Tip:</label>
                <div *ngFor="let tip of tipChoices | async">
                    <button (click)="onAddingTip(tip.amount)" [ngClass]="{'tip-buttons': !tip.active, 'tip-buttons-active': tip.active}">{{tip.label}} <br/>${{tip.amount.toFixed(2)}}</button>
                </div>
            </div>
            <div *ngIf="shoppingCartItems.length > 0" class="delivery-component" fxLayoutAlign="end">
                <mat-radio-group (change)="onSelectedOptionChange($event.value)" [(ngModel)]="selectedOption" class="dialog-product__radio-button">
                    <mat-radio-button value="delivery" (click)="toggleDeliverable('delivery')">Delivery</mat-radio-button>
                    <mat-radio-button class="pick-up-button" value="pickUp" (click)="toggleDeliverable('pickup')">Pick Up</mat-radio-button>
                    <mat-radio-button class="shipping-button" value="shipping" (click)="toggleDeliverable('shipping')">Shipping</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="shoppingCartItems.length > 0" class="shopping-cart-header" fxLayout="row" fxLayoutAlign="end">
                <div class="total-tag" fxLayout="column">
                    <div>
                        Tax
                    </div>
                    <div *ngIf="isDeliverable">
                        Delivery Fee
                    </div>
                    <div *ngIf="isShippable">
                        Shipping Fee
                    </div>
                    <div>
                        Tip
                    </div>
                    <div>
                        Total Amount
                    </div>
                </div>
                <div class="total-price-tag" fxLayout="column">
                    <div>
                        ${{taxAmount.toFixed(2)}}
                    </div>
                    <div *ngIf="isDeliverable">
                        ${{deliveryFee ? deliveryFee.toFixed(2) : 0}}
                    </div>
                    <div *ngIf="isShippable">
                        ${{shippingFee ? shippingFee.toFixed(2) : 0}}
                    </div>
                    <div>
                        ${{selectedTip ? selectedTip.toFixed(2) : 0}}
                    </div>
                    <div>
                        <strong style="font-size: 24px; color: green;">${{totalAmount.toFixed(2)}}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="shoppingCartItems.length > 0 && !isMobile">
        <app-checkout
                (extraFees)="extraFees($event)"
                (orderConformation)="orderConformation($event)"
                [delivery]="isDeliverable"
                [shipping]="isShippable"
                [shippingAmount]="shippingFee"
                [organizationId]="organizationId"
                [selectedTip]="selectedTip"
                [shoppingCartItems]="shoppingCartItems"
                [totalAmount]="totalAmount">
        </app-checkout>
    </div>

    <div *ngIf="shoppingCartItems.length <= 0 && !isMobile">
        <img alt="Your cart is empty" src="../assets/img/emptycart.png" title="Your cart is empty"/>
        <h3 [ngClass]="{'cart-message': !isMobile, 'mobile-cart-message': isMobile}">You have 0 items in shopping cart</h3>
        <div class="clearfix"></div>
        <button color="primary" mat-raised-button routerLink="/">Continue Ordering</button>
    </div>


    <div *ngIf="isMobile" fxLayout="column">
        <div>
            <div *ngIf="shoppingCartItems.length > 0" class="item-container">
                <div *ngFor="let item of shoppingCartItems"
                     class="row item-container2">
                    <div class="mobile-item-pic-container">
                        <img [src]="item.imageUrl ? item.imageUrl : 'http://mungaka.com/erestauImg/jayscaribbean/zz_oxtail.png'" alt="Sorry, Image not found" class="mobile-item-pic" mat-card-image>
                    </div>
                    <div class="mobile-name-description" fxLayout="column">
                        {{item.name}}
                        <div fxLayout="row">
                            Qty:
                            <div class="mobile-qty-container">
                                <mat-form-field class="item-quantity">
                                    <select (change)="getTheQtyEntered($event.target.value, item)" [ngModel]="item.quantity" matNativeControl>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div>
                        ${{item.finalPrice.toFixed(2)}}
                        <button (click)="removeItem(item)" mat-button>
                            <i style="color: brown;">Remove</i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="shoppingCartItems.length > 0" class="mobile-tip-component" fxLayout="column" fxLayoutAlign="end">
                <label class="tip-label">Tip:</label>
                <div fxLayout="row">
                    <div *ngFor="let tip of tipChoices | async">
                        <button (click)="onAddingTip(tip.amount)" [ngClass]="{'tip-buttons': !tip.active, 'tip-button-active': tip.active}">{{tip.label}} <br/>${{tip.amount.toFixed(2)}}</button>
                    </div>
                </div>
            </div>
            <div class="mobile-delivery-component" fxLayoutAlign="end">
                <mat-radio-group (change)="onSelectedOptionChange($event.value)" [(ngModel)]="selectedOption">

                    <mat-radio-button [ngClass]="{'disabled': isDeliverable}" value="delivery" (click)="toggleDeliverable('delivery')">Delivery</mat-radio-button>

                    <mat-radio-button class="pick-up-button" value="pickUp" (click)="toggleDeliverable('pickup')">Pick Up</mat-radio-button>
                    <mat-radio-button class="shipping-button" value="shipping" (click)="toggleDeliverable('shipping')">Shipping</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="shoppingCartItems.length > 0" class="shopping-cart-header" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="item-tag" fxLayout="column">
                    <div>
                        Tax
                    </div>

                    <div *ngIf="isDeliverable">
                        Delivery Fee
                    </div>
                    <div *ngIf="isShippable">
                        Shipping Fee
                    </div>
                    <div>
                        Tip
                    </div>
                    <div>
                        Total Amount
                    </div>
                </div>
                <div class="price-tag" fxLayout="column">
                    <div>
                        ${{taxAmount.toFixed(2)}}
                    </div>
                    <div *ngIf="isDeliverable">
                        ${{deliveryFee ? deliveryFee.toFixed(2) : 0}}
                    </div>
                    <div *ngIf="isShippable">
                        ${{shippingFee ? shippingFee.toFixed(2) : 0}}
                    </div>
                    <div>
                        ${{selectedTip ? selectedTip.toFixed(2) : 0}}
                    </div>
                    <div>
                        <strong style="font-size: 24px; color: green;">${{totalAmount.toFixed(2)}}</strong>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="shoppingCartItems.length <= 0" class="col-lg-9 col-md-8 col-sm-8 col-xs-12 float-left align-content-center">
            <img alt="Your cart is empty" src="../assets/img/emptycart.png" title="Your cart is empty"/>
            <h3 [ngClass]="{'cart-message': !isMobile, 'mobile-cart-message': isMobile}">You have 0 items in shopping cart</h3>
            <div class="clearfix"></div>
            <button color="primary" mat-raised-button routerLink="/">Continue Ordering</button>
        </div>
        <div *ngIf="shoppingCartItems.length > 0" [ngClass]="{'check-out-container': !isMobile, 'mobile-check-out-container': isMobile}" fxFlex>
            <app-checkout
                    (extraFees)="extraFees($event)"
                    (orderConformation)="orderConformation($event)"
                    [delivery]="isDeliverable"
                    [shipping]="isShippable"
                    [shippingAmount]="shippingFee"
                    [organizationId]="organizationId"
                    [selectedTip]="selectedTip"
                    [shoppingCartItems]="shoppingCartItems"
                    [totalAmount]="totalAmount">
            </app-checkout>
        </div>
    </div>
</div> -->
