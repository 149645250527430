import { Component, OnInit, HostListener } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { Router } from '@angular/router';
import { ShoppingCartService } from '../../../services/shopping-cart.service';
import { Observable, of } from 'rxjs';
import { HomeService } from '../../../services/home.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { LogoService } from '../../../services/logo.service';
import { WebworkService } from '../../../services/webwork.service';
import { Organization } from 'src/app/interface/organization';

@Component ({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss']
})

export class HeaderComponent implements OnInit {

    public isMobile: boolean;
    public itemsInCart$: Observable<any[]> = of ([]);
    public organizationAlias: any;
    public totalItemsInCart = 0;
    public companyLogoUrl: string;
    public deliveryHours: any;
    public geoLocation: any;
    public showGeoButton: boolean;
    public locationData: any;
    public geoMessage: string;
    public headerTab: any[] = [
        {
            label: 'HOME',
            active: false,
            disable: false
        },
        {
            label: 'MENU',
            active: false,
            disable: false
        }
    ];
    public organizations: Organization[];

    constructor(private responsiveService: ResponsiveService,
                private router: Router,
                private cartService: ShoppingCartService,
                private homeService: HomeService,
                public logoService: LogoService,
                public webwork: WebworkService,
                public dialog: MatDialog) {
        this.itemsInCart$ = this.cartService.getItems ();
        this.itemsInCart$.subscribe ((items) => {
            const allQuantity = items.map ((selected) => {
                return selected.quantity;
            });
            this.totalItemsInCart = allQuantity.reduce ((a, b) => {
                return a + b;
            }, 0);
            if ( this.totalItemsInCart > 0 ) {
                this.headerTab[0].disable = false;
                this.headerTab[1].disable = false;
            } else {
                this.headerTab[0].disable = false;
                this.headerTab[1].disable = true;
            }
        });
    }

    public searchVisible: boolean = false;
    

    ngOnInit() {
        this.onResize ();
        this.responsiveService.checkWidth ();
        this.showGeoButton = true;
        if ( window.location.href.includes ('menu') ) {
            this.headerTab[1].active = true;
        } else if ( window.location.href.includes ('home') ) {
            this.homeService.removeSelectedOrganization ();
            this.headerTab[0].active = true;
        } else {
            this.headerTab[1].active = false;
            this.headerTab[0].active = false;
        }
        this.companyLogoUrl = this.logoService.companyLogo;
        this.homeService.getOrganization ().subscribe ((selectedOrganization) => {
            if ( selectedOrganization ) {
                this.organizationAlias = selectedOrganization;
            }
        });

        this.homeService.getOrganizations ().subscribe ((organizations) => {
            this.organizations = organizations;
        });
    }

    closeSearch() {
        this.searchVisible = false;
    }

    openSearch() {
        this.searchVisible = true;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        const width = window.innerWidth;
        if (width <= 980) {
            this.searchVisible = false;
        } else {
            this.searchVisible = true;
        }

        this.responsiveService.getMobileStatus ().subscribe (isMobile => {
            this.isMobile = isMobile;
        });
    }

    onShoppingCartClick() {
        this.logoService.setPageData(this.organizationAlias);
        this.router.navigateByUrl ('/shopping-cart');
    }

    changeTopTab(i) {
        this.logoService.resetContext();
        if ( i === 0 ) {
            this.logoService.resetContext()
            this.homeService.removeSelectedOrganization ();
            if ( this.totalItemsInCart > 0 ) {
                const dialogRef = this.dialog.open (ConfirmationDialogComponent);
                dialogRef.afterClosed ().subscribe (item => {
                    this.headerTab[0].active = true;
                    this.headerTab[1].active = false;
                });
            } else {
                this.router.navigateByUrl ('/home');
                this.headerTab[0].active = true;
                this.headerTab[1].active = false;
            }
        } else if ( i === 1 ) {
            this.logoService.resetContext()
            this.router.navigateByUrl (`/menu/${ this.organizationAlias.alias }`);
            this.headerTab[1].active = true;
            this.headerTab[0].active = false;
        }
    }


    showData(position) {
        this.geoLocation = position;
        return this.geoLocation;
    }

    getUserDeliveryOptions() {
        this.showGeoButton = false;
        this.geoMessage = 'Checking on Geo';
        this.webwork.getLocation ();
        this.locationData = this.webwork.deliveryProximity;
    }
}

