<div (window:resize)="onResize()" *ngIf="!isMobile" class="confirmation-container">
    <div class="confirmation-text">
        Are you sure you want to go to home, all the items in the cart will be removed.
    </div>
    <div class="button-container" fxLayout="row" fxLayoutAlign="space-around center">
        <button (click)="onYesClick()" color="primary" mat-raised-button>Yes</button>
        <button (click)="onNoClick()" color="warn" mat-raised-button>No</button>
    </div>
</div>

<div (window:resize)="onResize()" *ngIf="isMobile" class="mobile-confirmation-container">
    <div class="mobile-confirmation-text">
        Are you sure you want to go to home, all the items in the cart will be removed.
    </div>
    <div class="button-container" fxLayout="row" fxLayoutAlign="space-around center">
        <button (click)="onYesClick()" color="primary" mat-raised-button>Yes</button>
        <button (click)="onNoClick()" color="warn" mat-raised-button>No</button>
    </div>
</div>
