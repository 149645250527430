import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable ({
    providedIn: 'root'
})
export class DebugService {
    private env: any;

    constructor(
        private http: HttpClient
    ) {
        this.env = environment;
    }

    logData(location, data) {
        if ( !this.env.production ) {
            // console.log (location + ': ' + data);
            // console.log (location + ': ');
            // console.dir (data);
        }
    }

}

